export function cleanParams(params) {
    for (const key of Object.keys(params)) {
        if (
            params[key] === "" ||
            typeof params[key] === "undefined" ||
            params[key] === null
        ) {
            delete params[key];
        }
    }

    return params;
}
