import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import AddTeacher from "./components/AddTeacher";
import AllCourseList from "./components/AllCourse";
import BatchSingle from "./components/BatchSingle";
import Classroom from "./components/Classroom";
import CreateBatch from "./components/CreateBatch";
import CreateClassroom from "./components/CreateClassroom";
import CreateCourse from "./components/CreateCourse";
import EnrollFrom from "./components/EnrollFrom";
import FollowUps from "./components/FollowUps";
import AssignedLeads from "./components/AssignedLeads";
import LeadList from "./components/LeadList";
import LeadSingle from "./components/LeadSingle";
import ListInTableView from "./components/ListInTableView";
import Login from "./components/Login";
import Notifications from "./components/Notifications";
import Password from "./components/Password";
import Profile from "./components/Profile";
import Register from "./components/Register";
import SideBar from "./components/SideBar";
import TableValues from "./components/Table";
import Users from "./components/Users";
import Dashboard from "./screens/Dashboard";
import LeadForm from "./screens/LeadForm";
import Leads from "./screens/Leads";
import UploadLeads from "./components/UploadLeads";
import Attendance from "./components/Attendance";
import UserResources from "./api/UserResources";
import EnrollmentList from "./components/EnrollmentList";
import PaymentSingle from "./components/PaymentSingle";
import SuccessFail from "./components/SuccessFail";
import MyClasses from "./components/MyClasses";
import AllCounselors from "./components/AllCounselors";
import PrivateRoute from "./components/PrivateRoute";
import PaymentHistory from "./components/PaymentHistory";
// import errorPage from "./components/ErrorPage";
import BreakHistory from "./components/BreakHistory";
import SalesForm from "./components/SalesForm";
import Sales from "./components/Sales";
import ErrorPage from "./components/ErrorPage";

const rows = [
  {
    name: "Stock market",
    contact: 12344444423,
    status: "Active",
    teacher: "Tony Stark",
    email: "aj@example.com",
  },
  {
    name: "Financial advisor",
    contact: 12344444423,
    teacher: "Bruce Wayne",
    status: "Inactive",
    email: "seema.ra@example.com",
  },
];

const enroll = [
  {
    name: "Ajay Singh",
    contact: 12344444423,
    email: "aj@example.com",
    course: "Finance Advisor",
    payment_status: "Partially Paid",
  },
  {
    name: "Shivani Singh",
    contact: 12344444423,
    email: "shivani.ra@example.com",
    course: "Trading Advisor",
    payment_status: "Fully Paid",
  },
];
const classroomData = [
  {
    className: "BAT21",
    course: "Finance Advisor",
    faculty: "Tony Stark",
  },
  {
    className: "BAT32",
    course: "Trading Advisor",
    faculty: "Bruce Wayne",
  },
];

function App() {
  const isLogin = useSelector((state) => state.user.loggedIn);
  // const navigate = useNavigate();

  const userInfo = useSelector((state) => state.user.userInfo);

  // React.useEffect(() => {
  // 	if (isLogin) {
  // 		return navigate("/dashboard");
  // 	}

  // 	// return navigate("/register");
  // }, [isLogin]);

  return (
    <>
      <Routes>
        <Route path="*" element={<ErrorPage />}></Route>
        <Route element={<PrivateRoute isLogin={isLogin} />}>
          <Route path="/" element={<Dashboard />} />
          <Route
            path="/dashboard"
            element={userInfo.role_type == 4 ? <Attendance /> : <Dashboard />}
          />
          <Route path="/create-lead" element={<LeadForm />} />
          <Route path="/form-status" element={<SuccessFail />} />
          <Route path="/leads" element={<Leads />} />
          <Route
            path="/view-lead/:id"
            element={<LeadSingle userType={userInfo.role_type} />}
          />
          <Route path="/view-batch/:id" element={<BatchSingle />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/password" element={<Password />} />
          <Route path="/create-batch" element={<CreateBatch />} />
          {/* <Route path="/notifications" element={<Notifications />} /> */}
          <Route path="*" element={<Dashboard />} />
          <Route
            path="/break-resume-history"
            element={<BreakHistory PageLabel={"Break History"} />}
          />
          <Route
            path="/all-batches"
            element={<ListInTableView PageLabel={"All Batches"} rows={rows} />}
          />
          <Route
            path="/all-counsellors"
            element={<AllCounselors PageLabel={"All Counsellors"} />}
          />
          <Route path="/create-course" element={<CreateCourse />} />
          <Route
            path="/all-courses"
            element={
              <AllCourseList
                PageLabel={"All Courses"}
                userType={userInfo.role_type}
                rows={rows}
              />
            }
          />
          <Route
            path="/all-leads"
            element={<LeadList PageLabel={"All Leads"} />}
          />
          <Route
            path="/assigned-leads"
            element={<AssignedLeads PageLabel={"Assigned Leads"} />}
          />
          <Route
            path="/upload-leads"
            element={<UploadLeads PageLabel={"Upload Leads"} />}
          />
          <Route
            path="/students"
            element={
              <Users
                PageLabel={"All Students"}
                isStudent={true}
                userType={userInfo}
              />
            }
          />
          <Route
            path="/users"
            element={
              <Users PageLabel={"All Users"} isAdmin={true} isAllUsers={true} />
            }
          />
          <Route
            path="/sales-form"
            element={userInfo.role_type == 1 ? <SalesForm /> : <ErrorPage />}
          />
          <Route path="/sales" element={<Sales />} />
          <Route path="/follow-ups" element={<FollowUps />} />
          <Route path="/my-classes" element={<MyClasses />} />
          <Route path="/payment-history" element={<PaymentHistory />} />
          <Route path="/create-classroom" element={<CreateClassroom />} />
          <Route
            path="/all-classroom"
            element={
              <Classroom
                PageLabel={"Classrooms"}
                rows={classroomData}
                isClassroom={true}
              />
            }
          />
          <Route path="/add-teacher" element={<AddTeacher />} />
          <Route
            path="/create-enrollment/:id"
            element={<EnrollFrom userType={userInfo.role_type} />}
          />
          <Route
            path="/all-enrollment"
            element={
              <EnrollmentList
                PageLabel={"All Enrollment"}
                rows={enroll}
                isEnrollment={true}
                isStudent={true}
              />
            }
          />
          <Route
            path="/all-enrollment/:id"
            element={
              <PaymentSingle
                PageLabel={"Payment Details"}
                rows={enroll}
                isEnrollment={true}
                isStudent={true}
              />
            }
          />
          <Route
            path="/enrollment/:id"
            element={<LeadSingle isEnrollmentSingle={true} />}
          />

          <Route
            path="/batch/:id"
            element={<BatchSingle isBatchView={true} />}
          />
          <Route
            path="/converted-leads"
            element={<TableValues title={"Converted Leads"} />}
          />

          <Route
            path="/attendance"
            element={<Attendance title={"Converted Leads"} />}
          />
          <Route path="/add-user" element={<Register isLogin={isLogin} />} />
        </Route>

        <Route path="/login" element={<Login />} />
        <Route
          path="/reset-password"
          element={<Password isRestPassword={true} />}
        />

        <Route path="/create-lead" element={<LeadForm isPublic={true} />} />
        <Route path="/form-status" element={<SuccessFail />} />
      </Routes>
    </>
  );
}

export default App;
