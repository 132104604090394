import React from "react";
import BarChartIcon from "@mui/icons-material/BarChart";
import PasswordIcon from "@mui/icons-material/Password";
import FlightClassIcon from "@mui/icons-material/FlightClass";
import CreateIcon from "@mui/icons-material/Create";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupsIcon from "@mui/icons-material/Groups";
import LogoutIcon from "@mui/icons-material/Logout";
import ListIcon from "@mui/icons-material/List";
import PeopleIcon from "@mui/icons-material/People";
import Box from "@mui/material/Box";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  DocumentScanner,
  Person2Outlined,
  SupervisedUserCircleOutlined,
} from "@mui/icons-material";
import BookIcon from "@mui/icons-material/Book";
import SchoolIcon from "@mui/icons-material/School";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import UserResources from "../api/UserResources";
import { logout, login } from "../store/userSlice";
import { setUserInfo } from "../store/userSlice";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

import { useSelector } from "react-redux";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";

const SideBar = ({ userInfo }) => {
  const location = useLocation();
  // const userType = useSelector((state) => state.auth.userType)
  // const userInfo = useSelector((state) => state.user.userInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, loadingSet] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const dialerLogout = async (params) => {
    try {
      const ans = await UserResources.dialerLogout(params);
      alert(JSON.stringify(ans));
    } catch (error) {}
  };
  const Logout = async (e) => {
    loadingSet(true);
    try {
      const res = await UserResources.logout();
      if (res.status) {
        setSuccess(true);
        dispatch(logout());
        dispatch(login(false));
        dialerLogout({ user_email_id: userInfo.email });
        navigate("/login");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
    loadingSet(false);
  };

  // React.useEffect(() => {
  //     dialerLogout();
  //     console.log("succcess", success);
  // }, [success]);

  return (
    <>
      {userInfo.role_type == 1 ? (
        <Admin location={location.pathname} />
      ) : userInfo.role_type == 2 ? (
        <Counsellor location={location.pathname} />
      ) : userInfo.role_type == 4 ? (
        <Students location={location.pathname} />
      ) : (
        <HeadCounsellor location={location.pathname} />
      )}

      <ListItemButton
        onClick={() => {
          Logout();
          console.log(1);
        }}
      >
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary={loading ? "Please wait.." : "Logout"} />
      </ListItemButton>
    </>
  );
};

export default SideBar;

const Students = () => {
  return (
    <React.Fragment>
      <Link
        to={"/dashboard"}
        style={{ textDecoration: "none", color: "#000000DE" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
      </Link>
      <Link
        to={"/my-classes"}
        style={{ textDecoration: "none", color: "#000000DE" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <SchoolIcon />
          </ListItemIcon>
          <ListItemText primary="My Classes" />
        </ListItemButton>
      </Link>
      <ListItemButton component={Link} to="attendance">
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Attendance" />
      </ListItemButton>
      <ListItemButton component={Link} to="/payment-history">
        <ListItemIcon>
          <CurrencyRupeeIcon />
        </ListItemIcon>
        <ListItemText primary="Payment History" />
      </ListItemButton>
      <Link
        to={"/profile"}
        style={{ textDecoration: "none", color: "#000000DE" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </ListItemButton>
      </Link>
      <Link
        to={"/password"}
        style={{ textDecoration: "none", color: "#000000DE" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary="Password" />
        </ListItemButton>
      </Link>
    </React.Fragment>
  );
};

const Counsellor = ({ location }) => {
  const [show, setShow] = React.useState(false);
  console.log(location);
  return (
    <React.Fragment>
      <Link
        to={"/dashboard"}
        style={{ textDecoration: "none", color: "#000000DE" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
      </Link>

      <ListItemButton onClick={() => setShow(!show)}>
        <ListItemIcon>
          <DocumentScanner />
        </ListItemIcon>
        <ListItemText primary="Leads" />
      </ListItemButton>

      {show && (
        <Box pl={0} bgcolor={"#c5cae959"}>
          {/* <Link
                        to={"/create-lead"}
                        style={{ textDecoration: "none", color: "#000000DE" }}
                    >
                        <ListItemButton>
                            <ListItemIcon>
                                <CreateIcon />
                            </ListItemIcon>
                            <ListItemText primary="Create Leads" />
                        </ListItemButton>
                    </Link> */}
          <Link
            to={"/assigned-leads"}
            style={{ textDecoration: "none", color: "#000000DE" }}
          >
            <ListItemButton ml={20}>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="Assigned Leads" />
            </ListItemButton>
          </Link>
        </Box>
      )}

      <Link
        to={"/follow-ups"}
        style={{ textDecoration: "none", color: "#000000DE" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Follow Up" />
        </ListItemButton>
      </Link>
      <Link
        to={"/sales"}
        style={{ textDecoration: "none", color: "#000000DE" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary="Sales" />
        </ListItemButton>
      </Link>
      <Link
        to={"/profile"}
        style={{ textDecoration: "none", color: "#000000DE" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </ListItemButton>
      </Link>
      <Link
        to={"/password"}
        style={{ textDecoration: "none", color: "#000000DE" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary="Password" />
        </ListItemButton>
      </Link>
    </React.Fragment>
  );
};

const HeadCounsellor = () => {
  const [show, setShow] = React.useState(false);

  return (
    <React.Fragment>
      <Link
        to={"/dashboard"}
        style={{ textDecoration: "none", color: "#000000DE" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
      </Link>

      <Link
        to={"/converted-leads"}
        style={{ textDecoration: "none", color: "#000000DE" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <SupervisedUserCircleOutlined />
          </ListItemIcon>
          <ListItemText primary="Leads" />
        </ListItemButton>
      </Link>

      <Link
        to="/all-enrollment"
        style={{ textDecoration: "none", color: "#000000DE" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <CreateIcon />
          </ListItemIcon>
          <ListItemText primary="All Enrollment" />
        </ListItemButton>
      </Link>
      <ListItemButton onClick={() => setShow(!show)}>
        <ListItemIcon>
          <DocumentScanner />
        </ListItemIcon>
        <ListItemText primary="Enrollment" />
      </ListItemButton>
      {show && (
        <Box pl={0} bgcolor={"#c5cae959"}>
          <Link
            to={"/create-enrollment"}
            style={{
              textDecoration: "none",
              color: "#000000DE",
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <CreateIcon />
              </ListItemIcon>
              <ListItemText primary="Create Enrollment" />
            </ListItemButton>
          </Link>
          <Link
            to={"/all-enrollment"}
            style={{
              textDecoration: "none",
              color: "#000000DE",
            }}
          >
            <ListItemButton ml={20}>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="All Enrollment" />
            </ListItemButton>
          </Link>
        </Box>
      )}

      <Link
        to={"/follow-ups"}
        style={{ textDecoration: "none", color: "#000000DE" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Follow Up" />
        </ListItemButton>
      </Link>
      <Link
        to={"/profile"}
        style={{ textDecoration: "none", color: "#000000DE" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </ListItemButton>
      </Link>
    </React.Fragment>
  );
};

const Admin = ({ onLogout }) => {
  const [showBatches, setShowBatches] = React.useState(false);
  const [showClasses, setShowClasses] = React.useState(false);
  const [showCourses, setShowCourses] = React.useState(false);
  const [showClassRooms, setShowClassRooms] = React.useState(false);
  // const [loading, loadingSet] = React.useState(false);
  const [show, setShow] = React.useState(false);

  // const dispatch = useDispatch();

  return (
    <React.Fragment>
      <Link
        to={"/dashboard"}
        style={{ textDecoration: "none", color: "#000000DE" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
      </Link>

      <ListItemButton onClick={() => setShow(!show)}>
        <ListItemIcon>
          <DocumentScanner />
        </ListItemIcon>
        <ListItemText primary="Leads" />
        {show ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>

      {show && (
        <Box pl={0} bgcolor={"#c5cae959"}>
          <Link
            to={"/create-lead"}
            style={{ textDecoration: "none", color: "#000000DE" }}
          >
            <ListItemButton>
              <ListItemIcon>
                <CreateIcon />
              </ListItemIcon>
              <ListItemText primary="Create Leads" />
            </ListItemButton>
          </Link>
          <Link
            to={"/all-leads"}
            style={{ textDecoration: "none", color: "#000000DE" }}
          >
            <ListItemButton ml={20}>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="All Leads" />
            </ListItemButton>
          </Link>
        </Box>
      )}

      <Link
        to={"/follow-ups"}
        style={{ textDecoration: "none", color: "#000000DE" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <ContactPhoneIcon />
          </ListItemIcon>
          <ListItemText primary="Follow Up" />
        </ListItemButton>
      </Link>

      <Link
        to={"/add-user"}
        style={{ textDecoration: "none", color: "#000000DE" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Add User" />
        </ListItemButton>
      </Link>
      <ListItemButton onClick={() => setShowBatches(!showBatches)}>
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        <ListItemText primary="Batches" />
        {showBatches ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>

      {showBatches && (
        <Box pl={0} bgcolor={"#c5cae959"}>
          <Link
            to={"/create-batch"}
            style={{ textDecoration: "none", color: "#000000DE" }}
          >
            <ListItemButton>
              <ListItemIcon>
                <CreateIcon />
              </ListItemIcon>
              <ListItemText primary="Create Batch" />
            </ListItemButton>
          </Link>
          <Link
            to={"/all-batches"}
            style={{ textDecoration: "none", color: "#000000DE" }}
          >
            <ListItemButton ml={20}>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="All Batches" />
            </ListItemButton>
          </Link>
        </Box>
      )}
      <ListItemButton onClick={() => setShowClasses(!showClasses)}>
        <ListItemIcon>
          <Person2Outlined />
        </ListItemIcon>
        <ListItemText primary="Teachers" />
        {showClasses ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>

      {showClasses && (
        <Box pl={0} bgcolor={"#c5cae959"}>
          <Link
            to="/add-teacher"
            style={{ textDecoration: "none", color: "#000000DE" }}
          >
            <ListItemButton>
              <ListItemIcon>
                <CreateIcon />
              </ListItemIcon>
              <ListItemText primary="Add Teacher" />
            </ListItemButton>
          </Link>
        </Box>
      )}

      <ListItemButton onClick={() => setShowCourses(!showCourses)}>
        <ListItemIcon>
          <BookIcon />
        </ListItemIcon>
        <ListItemText primary="Courses" />
        {showCourses ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>

      {showCourses && (
        <Box pl={0} bgcolor={"#c5cae959"}>
          <Link
            to="/create-course"
            style={{ textDecoration: "none", color: "#000000DE" }}
          >
            <ListItemButton>
              <ListItemIcon>
                <CreateIcon />
              </ListItemIcon>
              <ListItemText primary="Create Course" />
            </ListItemButton>
          </Link>
          <Link
            to={"/all-courses"}
            style={{ textDecoration: "none", color: "#000000DE" }}
          >
            <ListItemButton ml={20}>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="All Courses" />
            </ListItemButton>
          </Link>
        </Box>
      )}
      <Link
        to="/all-enrollment"
        style={{ textDecoration: "none", color: "#000000DE" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <CreateIcon />
          </ListItemIcon>
          <ListItemText primary="All Enrollment" />
        </ListItemButton>
      </Link>
      <ListItemButton onClick={() => setShowClassRooms(!showClassRooms)}>
        <ListItemIcon>
          <SchoolIcon />
        </ListItemIcon>
        <ListItemText primary="Classrooms" />
        {showClassRooms ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>

      {showClassRooms && (
        <Box pl={0} bgcolor={"#c5cae959"}>
          <Link
            to={"/create-classroom"}
            style={{ textDecoration: "none", color: "#000000DE" }}
          >
            <ListItemButton>
              <ListItemIcon>
                <CreateIcon />
              </ListItemIcon>
              <ListItemText primary="Create New" />
            </ListItemButton>
          </Link>
          <Link
            to={"/all-classroom"}
            style={{ textDecoration: "none", color: "#000000DE" }}
          >
            <ListItemButton ml={20}>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="All ClassRooms" />
            </ListItemButton>
          </Link>
        </Box>
      )}
      <Link
        to={"/students"}
        style={{ textDecoration: "none", color: "#000000DE" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <FlightClassIcon />
          </ListItemIcon>
          <ListItemText primary="Students" />
        </ListItemButton>
      </Link>
      <Link
        to={"/all-counsellors"}
        style={{ textDecoration: "none", color: "#000000DE" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <FlightClassIcon />
          </ListItemIcon>
          <ListItemText primary="Counsellors" />
        </ListItemButton>
      </Link>
      <Link
        to={"/break-resume-history"}
        style={{ textDecoration: "none", color: "#000000DE" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <FlightClassIcon />
          </ListItemIcon>
          <ListItemText primary="Break History" />
        </ListItemButton>
      </Link>

      <Link
        to={"/users"}
        style={{ textDecoration: "none", color: "#000000DE" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <GroupsIcon />
          </ListItemIcon>
          <ListItemText primary="All Users" />
        </ListItemButton>
      </Link>
      {/* <Link
        to={"/sales"}
        style={{ textDecoration: "none", color: "#000000DE" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary="Sales" />
        </ListItemButton>
      </Link> */}
      <Link
        to={"/profile"}
        style={{ textDecoration: "none", color: "#000000DE" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </ListItemButton>
      </Link>
      <Link
        to={"/password"}
        style={{ textDecoration: "none", color: "#000000DE" }}
      >
        <ListItemButton>
          <ListItemIcon>
            <PasswordIcon />
          </ListItemIcon>
          <ListItemText primary="Password" />
        </ListItemButton>
      </Link>
    </React.Fragment>
  );
};
