import React, { useEffect } from "react";
import CustomTablePaginator from "./CustomTablePaginator";
import {
  Typography,
  Box,
  Tooltip,
  FormControl,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useState } from "react";
import Paper from "@mui/material/Paper";
import UserResources from "../api/UserResources";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

export default function Sales() {
  const userInfo = useSelector((state) => state.user.userInfo);
  console.log("====================================");
  console.log("userInfo", userInfo);
  const navigate = useNavigate();
  // console.log("====================================");
  // const [loading, setLoading] = useState(false);
  const [paginationData, paginationDataSet] = useState([]);
  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(10);
  // const [open, setOpen] = React.useState(false);
  // const [counsellorLog, setCounsellorLog] = useState();
  // const [callLogs, callLogsSet] = useState([]);
  // const [data, setData] = useState([]);
  // const [openAllSalesModal, setOpenAllSalesModal] = useState(false);
  const [filterSection, setFilterSection] = useState();
  // const [userId, setUserId] = useState();
  const allSeles = async (userId) => {
    const params = {
      keyword: filterSection,
      limit: rpg,
      page: pg,
      user_id: userInfo.role_type == 2 ? userInfo.id : undefined,
    };

    Object.keys(params).forEach((key) => {
      if (params[key] === undefined) {
        delete params[key];
      }
    });

    try {
      const res = await UserResources.allSales(params);
      console.log("respose", res);
      paginationDataSet(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    allSeles();
  }, [rpg, pg, filterSection]);
  useEffect(() => {
    allSeles();
  }, []);
  return (
    <Box p={5}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" gutterBottom>
          All Sales
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Tooltip title="Clear Filter" cursor="pointer">
            <HighlightOffIcon
              onClick={() => {
                // FollowUps();
                setFilterSection("");
              }}
            />
          </Tooltip>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <TextField
              id="outlined-password-input"
              label="Search.."
              type="text"
              autoComplete="current-password"
              value={filterSection}
              onChange={(event) => setFilterSection(event.target.value)}
            />
          </FormControl>
        </Box>
      </Box>

      <TableContainer component={Paper} sx={{ margin: "20px 0" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>phone</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>GST</TableCell>
              <TableCell>Incentive Percentage</TableCell>
              <TableCell>Incentive Amount</TableCell>
              <TableCell>Total Amount</TableCell>
              {userInfo.role_type == 1 && <TableCell>Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginationData.data?.map((val, index) => (
              <TableRow key={index}>
                <TableCell>{val?.id}</TableCell>
                <TableCell>{val?.user?.name}</TableCell>
                <TableCell>{val?.user?.email}</TableCell>{" "}
                <TableCell>{val?.user?.phone}</TableCell>
                <TableCell>{val?.date}</TableCell>
                <TableCell>{val?.gst_percentage}</TableCell>
                <TableCell>{val?.incentive_percentage}</TableCell>
                <TableCell>{val?.incentive_amount}</TableCell>
                <TableCell>{val?.total_amount}</TableCell>
                {userInfo.role_type == 1 && (
                  <TableCell>
                    <Button
                      onClick={() =>
                        navigate("/sales-form", {
                          state: { val, isUpdate: true },
                        })
                      }
                      sx={{ mt: 1, mr: 1, mb: 5 }}
                      variant="contained"
                    >
                      Update
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CustomTablePaginator
        count={paginationData?.paginate_data?.total}
        rowsPerPage={rpg}
        page={pg}
        onPageChange={(newPage) => {
          setpg(newPage);
        }}
        onChangeRowsPerPage={(newRowsPerPage) => {
          setrpg(parseInt(newRowsPerPage));
          setpg(0);
        }}
      />
    </Box>
  );
}
