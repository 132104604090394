import React from "react";
import { Outlet } from "react-router-dom";
import Layout from "./Layout";
import SideBar from "./SideBar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function PrivateRoute({ isLogin, children }) {
	const userInfo = useSelector((state) => state.user.userInfo);

	const navigate = useNavigate();

    // console.log('isLogin', isLogin)
	React.useEffect(() => {
		if (!isLogin) {
			navigate("/login");
		}
	}, []);

	return (
		<Layout userInfo={userInfo} SideBar={<SideBar userInfo={userInfo} />}>
			{children ? children : <Outlet />}
		</Layout>
	);
}
