import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import UserResources from "../api/UserResources";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomTablePaginator from "./CustomTablePaginator";
import DatePicker from "react-datepicker";
import { Pattern } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
// import { TablePagination } from "@mui/material";

export default function AllCounselors({ PageLabel }) {
  const userInfo = useSelector((state) => state.user.userInfo);

  const navigate = useNavigate();
  const [paginationData, paginationDataSet] = useState([]);
  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(10);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [counsellorLog, setCounsellorLog] = useState();
  const [callLogs, callLogsSet] = useState([]);
  const [data, setData] = useState([]);
  const [openAllSalesModal, setOpenAllSalesModal] = useState(false);
  const [filterSection, setFilterSection] = useState();
  const [userId, setUserId] = useState();
  const [dateRange, setDateRange] = React.useState(["", ""]);
  const [startDate, endDate] = dateRange;

  let check;
  if (counsellorLog !== undefined) {
    check = callLogs.filter((val) => val.user_id === counsellorLog.id);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenAllSalesModal = () => {
    setOpenAllSalesModal(true);
  };

  const handleCloseAllSalesModal = () => {
    setOpenAllSalesModal(false);
  };

  // Date Pattern
  function formatDateString(inputDateString) {
    const inputDate = new Date(inputDateString);

    const year = inputDate.getFullYear();
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
    const day = inputDate.getDate().toString().padStart(2, "0");

    const outputDateString = `${year}-${month}-${day}`;

    return outputDateString;
  }

  const allSeles = async (userId) => {
    const params = {
      keyword: filterSection,
      limit: rpg,
      page: pg,
      user_id: userId,
      start_date: formatDateString(startDate),
      end_date: formatDateString(endDate),
    };

    Object.keys(params).forEach((key) => {
      if (
        params[key] === undefined ||
        params[key] === null ||
        params[key] === "NaN-NaN-NaN" ||
        params[key] === "1970-01-01"
      ) {
        delete params[key];
      }
    });

    try {
      const res = await UserResources.allSales(params);
      paginationDataSet(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCounsellors = async (params) => {
    setLoading(true);

    try {
      const res = await UserResources.user_list({
        role_type: 2,
        limit: 9999,
      });
      setData(res.data.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchCounsellors();
  }, []);
  useEffect(() => {
    allSeles(userId);
  }, [rpg, pg, filterSection, endDate]);

  const fetchCallLogs = async (params) => {
    setLoading(true);

    try {
      const res = await UserResources.callLogs(params);
      if (res.status) {
        callLogsSet(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchCallLogs();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" gutterBottom>
          {PageLabel}
        </Typography>
        <Box>
          {userInfo.role_type == 1 && (
            <Button
              size="small"
              variant="contained"
              color="primary"
              disableElevation
              sx={{
                whiteSpace: "nowrap",
                textTransform: "capitalize",
                marginLeft: 2,
              }}
              onClick={(e) => {
                navigate("/sales-form");
              }}
            >
              + Sales
            </Button>
          )}
        </Box>
      </Box>
      <TableContainer component={Paper} px={30}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>S. No.</TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Extension ID</TableCell>

              <TableCell>Email</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              // data.map((row, index) => (
              data.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>

                  <TableCell>{row.name}</TableCell>

                  <TableCell>{row.phone}</TableCell>

                  <TableCell>
                    {row.extension_no && row.extension_no !== null
                      ? row.extension_no
                      : "NA"}
                  </TableCell>

                  <TableCell>{row.email}</TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      disableElevation
                      sx={{
                        whiteSpace: "nowrap",
                        textTransform: "capitalize",
                      }}
                      onClick={(e) => {
                        handleClickOpen(e);
                        setCounsellorLog(row);
                      }}
                    >
                      Call Logs
                    </Button>
                    {userInfo.role_type == 2 ||
                      (userInfo.role_type == 1 && (
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          disableElevation
                          sx={{
                            whiteSpace: "nowrap",
                            textTransform: "capitalize",
                            marginLeft: 2,
                          }}
                          onClick={(e) => {
                            allSeles(row?.id);
                            setUserId(row.id);
                            handleOpenAllSalesModal(e);
                          }}
                        >
                          All Sales
                        </Button>
                      ))}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {check?.length !== 0 ? (
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Call Logs - {counsellorLog?.name}
              </Typography>
              <Button autoFocus color="inherit" onClick={handleClose}>
                Close
              </Button>
            </Toolbar>
          </AppBar>
          <List>
            {callLogs &&
              callLogs.map((i, k) => (
                <ListItem
                  key={k}
                  sx={{ borderBottom: "1px solid #ddd" }}
                  secondaryAction={
                    <a href={i.recording_url} target="_blank" rel="noreferrer">
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        disableElevation
                        sx={{
                          whiteSpace: "nowrap",
                          textTransform: "capitalize",
                        }}
                      >
                        Play
                      </Button>
                    </a>
                  }
                >
                  <ListItemText secondary={i.lead_id} primary={"Lead ID"} />
                  <ListItemText secondary={i.phone} primary={"Call Made To"} />
                  <ListItemText
                    secondary={i.call_duration}
                    primary={"Call Duration"}
                  />
                  <ListItemText
                    secondary={i.start_time}
                    primary={"Start Time"}
                  />
                  <ListItemText secondary={i.end_time} primary={"End Time"} />
                  <ListItemText secondary={i.status} primary={"Status"} />
                </ListItem>
              ))}
          </List>
        </Dialog>
      ) : (
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Call Logs - No Data
              </Typography>
              <Button autoFocus color="inherit" onClick={handleClose}>
                Close
              </Button>
            </Toolbar>
          </AppBar>
          <List>
            <Box sx={{ textAlign: "center", fontSize: 42 }}>No Data</Box>
          </List>
        </Dialog>
      )}

      <Dialog
        fullScreen
        open={openAllSalesModal}
        onClose={handleCloseAllSalesModal}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseAllSalesModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              All Sales
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={handleCloseAllSalesModal}
            >
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <Box p={5}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                className="dateRange"
                isClearable={true}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tooltip title="Clear Filter" cursor="pointer">
                <HighlightOffIcon
                  onClick={() => {
                    // FollowUps();
                    setFilterSection("");
                  }}
                />
              </Tooltip>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <TextField
                  id="outlined-password-input"
                  label="search.."
                  type="text"
                  autoComplete="current-password"
                  value={filterSection}
                  onChange={(event) => setFilterSection(event.target.value)}
                />
              </FormControl>
            </Box>
          </Box>

          <TableContainer component={Paper} sx={{ margin: "20px 0" }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>phone</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>GST</TableCell>
                  <TableCell>Incentive Percentage</TableCell>
                  <TableCell>Incentive Amount</TableCell>
                  <TableCell>Total Amount</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading &&
                  paginationData.data?.map((val, index) => (
                    <TableRow key={index}>
                      <TableCell>{val?.id}</TableCell>
                      <TableCell>{val?.user?.name}</TableCell>
                      <TableCell>{val?.user?.email}</TableCell>{" "}
                      <TableCell>{val?.user?.phone}</TableCell>
                      <TableCell>{val?.date}</TableCell>
                      <TableCell>{val?.gst_percentage}</TableCell>
                      <TableCell>{val?.incentive_percentage}</TableCell>
                      <TableCell>{val?.incentive_amount}</TableCell>
                      <TableCell>{val?.total_amount}</TableCell>
                      <TableCell>
                        <Button
                          onClick={() =>
                            navigate("/sales-form", {
                              state: { val, isUpdate: true },
                            })
                          }
                          sx={{ mt: 1, mr: 1, mb: 5 }}
                          variant="contained"
                        >
                          Update
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <CustomTablePaginator
            count={paginationData?.paginate_data?.total}
            rowsPerPage={rpg}
            page={pg}
            onPageChange={(newPage) => {
              setpg(newPage);
            }}
            onChangeRowsPerPage={(newRowsPerPage) => {
              setrpg(parseInt(newRowsPerPage));
              setpg(0);
            }}
          />
        </Box>
      </Dialog>
    </>
  );
}

// End of the file
