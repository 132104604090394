import React from "react";
import { Card, CardContent } from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";

const rows = [
	{
		id: 1,
		name: "Ajay Mathur",
		leadID: 23,
		contact: 12344444423,
		email: "aj@example.com",
	},
	{
		id: 2,
		name: "Seema Rajput",
		leadID: 24,
		contact: 12344444423,
		email: "seema.ra@example.com",
	},
];

export default function TableValues({ title }) {
	return (
		<>
			<Card elevation={0}>
				<CardContent sx={{ fontSize: "20px" }}>{title}</CardContent>
			</Card>
			<TableContainer component={Paper} px={30}>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>S. No.</TableCell>
							<TableCell>Lead ID</TableCell>
							<TableCell>Name</TableCell>
							<TableCell>Contact No</TableCell>
							<TableCell>Email</TableCell>
							<TableCell>Action</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row, index) => (
							<TableRow key={index}>
								<TableCell scope="row">{index + 1}</TableCell>
								<TableCell>{row.leadID}</TableCell>
								<TableCell>{row.name}</TableCell>
								<TableCell>{row.contact}</TableCell>
								<TableCell>{row.email}</TableCell>
								<TableCell>
									<Link
										to={`/view-lead/${row.id}`}
                                        state={{ data: row }}
                                        style={{marginRight: 10}}
									>
                                        <Button variant="contained" disableElevation>
                                        View
                                        </Button>
									</Link>
									<Link
										to={`/create-enrollment?page=enroll`}
										state={{ data: row }}
									>
                                        <Button variant="contained" disableElevation>
                                            Enrollment
                                        </Button>
									</Link>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}
