import React, { useRef } from "react";
import TextField from "@mui/material/TextField";
import { Box, Card, CardContent, FormLabel, Typography, IconButton, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import dateFormat from "dateformat";
import UserResources from "../api/UserResources";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

export default function CreateBatch() {
    const [openSnackbar, openSnackbarSet] = React.useState(false);
    const [message, messageSet] = React.useState("");
    const [loading, loadingSet] = React.useState(false);
    const [batchName, batchNameSet] = React.useState("");
    const [startTime, setStartTime] = React.useState("");
    const [endTime, setEndTime] = React.useState("");
    const [startDate, setStartDate] = React.useState(dateFormat(new Date(), "yyyy-MM-dd"));
    const [endDate, setEndDate] = React.useState("");
    const timeInput = useRef();

    // const TextInputField = React.forwardRef({ ...rest }) => return (
    //
    // );

    const TextInputField = React.forwardRef((ref, props) => {
        const { component: Component, ...other } = ref;
        return (
            <input
                ref={timeInput}
                {...other}
                type="time"
                step={1}
                min={"00:00:00"}
                max={"24:00:00"}
            />
        );
    });

    const handleFormReset = () => {
        setStartDate(dateFormat(new Date(), "yyyy-MM-dd"));
        batchNameSet("");
        setEndDate("");
    };

    const BatchCreateUpdate = async (e) => {
        e.preventDefault();

        loadingSet(true);
        try {
            console.log({
                name: batchName,
                start_date: startDate,
                end_date: endDate,
                status: 1,
                start_time: startTime,
                end_time: endTime,
            });
            const res = await UserResources.saveUpdateBatch({
                name: batchName,
                start_date: startDate,
                end_date: endDate,
                status: 1,
                start_time: startTime,
                end_time: endTime,
            });

            if (res.status) {
                openSnackbarSet(true);

                messageSet("successful update");
                handleFormReset();
            }
        } catch (error) {
            openSnackbarSet(true);
            console.log(error.response);

            messageSet("Failed update");
        }
        loadingSet(false);
    };

    return (
        <Box sx={{ p: 6 }}>
            <Typography variant="h4" gutterBottom style={{ textAlign: "center" }}>
                Create New Batch
            </Typography>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={(e) => openSnackbarSet(false)}
                message={message}
                TransitionComponent={TransitionUp}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={(e) => openSnackbarSet(false)}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />

            <Grid container spacing={3} maxWidth={"sm"} margin={"auto"}>
                <Grid item xs={12} sm={12}>
                    <Box component={"form"} onSubmit={BatchCreateUpdate}>
                        <Card>
                            <CardContent>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="batch_name"
                                    label="Batch Name"
                                    name="batch_name"
                                    autoComplete="batch_name"
                                    autoFocus
                                    value={batchName}
                                    onChange={(e) => batchNameSet(e.target.value)}
                                />
                                <FormLabel>Start Date</FormLabel>
                                <TextField
                                    sx={{ mt: 0 }}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    type="date"
                                    fullWidth
                                    id="startDate"
                                    name="startDate"
                                    autoComplete="startDate"
                                    autoFocus
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />

                                <FormLabel>End Date</FormLabel>

                                <TextField
                                    sx={{ mt: 0 }}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    type="date"
                                    fullWidth
                                    id="endDate"
                                    name="endDate"
                                    autoComplete="endDate"
                                    autoFocus
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                                <FormLabel>Start Time</FormLabel>
                                <TextField
                                    sx={{ mt: 0 }}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="StartTime"
                                    name="StartTime"
                                    value={startTime}
                                    InputProps={{
                                        inputComponent: TextInputField,
                                    }}
                                    onChange={(e) => setStartTime(e.target.value)}
                                />
                                <FormLabel>End Time</FormLabel>
                                <TextField
                                    sx={{ mt: 0 }}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="endTime"
                                    name="endTime"
                                    value={endTime}
                                    InputProps={{
                                        inputComponent: TextInputField,
                                    }}
                                    onChange={(e) => setEndTime(e.target.value)}
                                />

                                <Button
                                    sx={{ mt: 3, mb: 2 }}
                                    disableElevation
                                    loading={loading}
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                >
                                    {loading ? "Please wait.." : "Create batch"}
                                </Button>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
