import * as React from "react";
import TextField from "@material-ui/core/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { experimentalStyled as styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Slide } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";

import {
    Card,
    CardContent,
    Radio,
    RadioGroup,
    FormControlLabel,
    Typography,
} from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import UserResources from "../api/UserResources";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { cleanParams } from "./Helper";
import DatePicker, { CalendarContainer } from "react-datepicker";
import dateFormat from "dateformat";
const useStyles = makeStyles((theme) => ({
    paper: {
        // marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        // margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%",
        // marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}
export default function EnrollFrom() {
    const navigate = useNavigate();
    const classes = useStyles();
    const location = useLocation();
    const params = useParams();
    const [duration, setDuration] = React.useState("");

    const [message, setMessage] = React.useState("");
    const [openSnackbar, openSnackbarSet] = React.useState(false);

    const studentDetails = location?.state.data;
    const [date, setDate] = React.useState(new Date());
    const [loading, setLoading] = React.useState(false);
    const [courseData, courseDataSet] = React.useState([]);
    const [name, setName] = React.useState(studentDetails.name || "");
    const [nameErrorText, setNameErrorText] = React.useState("");
    const [email, setEmail] = React.useState(studentDetails.email || "");
    const [emailErrorText, setEmailErrorText] = React.useState("");

    const [phone, setPhone] = React.useState(studentDetails.phone || "");
    const [phoneErrorText, setPhoneErrorText] = React.useState("");

    const [gender, setGender] = React.useState("");
    const [genderErrorText, setGenderErrorText] = React.useState("");
    const [course, setCourse] = React.useState("");
    const [courseErrorText, setCourseErrorText] = React.useState("");

    const [comment, setComment] = React.useState("");
    const [zipcode, setZipcode] = React.useState("");
    const [zipErrorText, setZipErrorText] = React.useState("");

    const [state, setState] = React.useState("");
    const [stateErrorText, setStateErrorText] = React.useState("");

    const [city, setCity] = React.useState("");
    const [cityErrorText, setCityErrorText] = React.useState("");

    // const [feeAmount, feeAmountSet] = React.useState(1);
    const [feeAmount, feeAmountSet] = React.useState("");
    const [feeErrorText, feeErrorTextSet] = React.useState("");

    const [amount, setAmount] = React.useState("");
    const [amountError, setAmountError] = React.useState("");

    const Wrapper = ({ className, children }) => {
        return (
            <div style={{ color: "#fff" }}>
                <CalendarContainer className={className}>
                    <div style={{ background: "#f0f0f0", padding: "4px 8px" }}>
                        Select Date and Time
                    </div>
                    <div style={{ position: "relative" }}>{children}</div>
                </CalendarContainer>
            </div>
        );
    };
    const DateTime = ({ date, setDate }) => {
        return (
            <DatePicker
                selected={date}
                className="inputCss small"
                onChange={(date) => setDate(date)}
                showTimeSelect
                dateFormat="MMMM d, yyyy-mm-dd H:mm:ss"
                calendarContainer={Wrapper}
                withPortal
            />
        );
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        // Reset error messages
        setNameErrorText("");
        setEmailErrorText("");
        setGenderErrorText("");
        setPhoneErrorText("");
        setCourseErrorText("");
        setZipErrorText("");
        setStateErrorText("");
        setCityErrorText("");
        feeErrorTextSet("");
        setAmountError();

        let hasError = false;

        // Validate each field

        if (!name) {
            setNameErrorText("Required");
            hasError = true;
        }
        if (!email) {
            setEmailErrorText("Required");
            hasError = true;
        } else {
            const emailRegex =
                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

            if (!emailRegex.test(email)) {
                setEmailErrorText("Invalid email format");
                hasError = true;
            }
        }

        if (!gender) {
            setGenderErrorText("Required");
            hasError = true;
        }
        if (!phone) {
            setPhoneErrorText("Required");
            hasError = true;
        }
        if (!course) {
            setCourseErrorText("Required");
            hasError = true;
        }

        if (!zipcode) {
            setZipErrorText("Required");
            hasError = true;
        } else if (zipcode.length !== 6) {
            setZipErrorText("Length must be six");
            hasError = true;
        }

        if (!state) {
            setStateErrorText("Required");
            hasError = true;
        }
        if (!city) {
            setCityErrorText("Required");
            hasError = true;
        }
        if (!amount) {
            setAmountError("Required");
        } else {
            const parsedAmount = parseFloat(amount);
            if (isNaN(parsedAmount)) {
                setAmountError("Should be a valid number");
                hasError = true;
            } else {
                setAmount(parsedAmount);
            }
        }
        if (!feeAmount) {
            feeErrorTextSet("Required");
        }

        if (hasError) {
            return;
        }
        try {
            const res = await UserResources.enrollmentSaveAndUpdate(
                cleanParams({
                    payment_date: dateFormat(date, "yyyy-mm-dd"),
                    user_id: studentDetails.id,
                    course_id: course,
                    name: name,
                    email: email,
                    phone: parseInt(phone),
                    gender: gender,
                    city: city,
                    state: state,
                    pin_code: zipcode,
                    payment_type: feeAmount,
                    amount: amount,
                    comment: comment,
                })
            );

            if (res.status) {
                openSnackbarSet(true);
                setMessage("Enrollment created successfully!");
                setDuration();
                setName("");
                setCity("");
                setEmail("");
                setPhone("");
                setGender("");
                setAmount("");
                setState("");
                setComment("");
                setCourse("");
                feeAmountSet("");
                setZipcode("");
            }
        } catch (error) {
            console.log(error);
            openSnackbarSet(true);
            setMessage("Something went wrong! Please try later.");
        }
    };

    const CourseList = async (params) => {
        setLoading(true);
        var params = {
            limit: "",
            page: "",
        };

        try {
            const res = await UserResources.getCourseList(params);
            console.log("res", res);
            if (res.status) {
                courseDataSet(res.data.data);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    React.useEffect(() => {
        CourseList();
    }, []);

    // console.log("courses", courseData);

    return (
        <Card>
            <CardContent>
                <Box
                    gutterBottom
                    sx={{
                        textAlign: "center",
                        fontSize: "2rem",
                        marginBottom: 2,
                    }}
                >
                    Enrollment
                </Box>
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={(e) => openSnackbarSet(false)}
                    message={message}
                    TransitionComponent={TransitionUp}
                    action={
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={(e) => openSnackbarSet(false)}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Box>
                            <TextField
                                variant="outlined"
                                size="small"
                                required
                                type="name"
                                fullWidth
                                id="name"
                                label="Name"
                                name="name"
                                autoComplete="name"
                                value={name}
                                error={!!nameErrorText}
                                helperText={nameErrorText}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box>
                            <TextField
                                variant="outlined"
                                size="small"
                                required
                                type="email"
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                value={email}
                                error={!!emailErrorText}
                                helperText={emailErrorText}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box>
                            <TextField
                                variant="outlined"
                                required
                                type="number"
                                fullWidth
                                id="phone"
                                label="Phone"
                                name="phone"
                                autoComplete="phone"
                                size="small"
                                value={phone}
                                error={!!phoneErrorText}
                                helperText={phoneErrorText}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={gender}
                            >
                                <FormControlLabel
                                    id="female"
                                    value={"female"}
                                    control={<Radio />}
                                    label="Female"
                                    onChange={(e) => setGender(e.target.value)}
                                />
                                <FormControlLabel
                                    id="male"
                                    value={"male"}
                                    control={<Radio />}
                                    label="Male"
                                    onChange={(e) => setGender(e.target.value)}
                                />
                                <FormControlLabel
                                    id="others"
                                    value={"others"}
                                    control={<Radio />}
                                    label="Others"
                                    onChange={(e) => setGender(e.target.value)}
                                />
                            </RadioGroup>
                        </FormControl>
                        {genderErrorText && (
                            <Typography variant="caption" color="error">
                                {genderErrorText}
                            </Typography>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box>
                            <FormControl fullWidth>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="city"
                                    label="City"
                                    name="city"
                                    size="small"
                                    value={city}
                                    error={!!cityErrorText}
                                    helperText={cityErrorText}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                            </FormControl>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box>
                            <FormControl fullWidth>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="state"
                                    label="State"
                                    name="state"
                                    size="small"
                                    value={state}
                                    error={!!stateErrorText}
                                    helperText={stateErrorText}
                                    onChange={(e) => setState(e.target.value)}
                                />
                            </FormControl>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                                Course
                            </InputLabel>
                            <Select
                                required
                                fullWidth
                                id="Course"
                                label="Course"
                                name="Course"
                                autoComplete="Course"
                                value={course}
                                onChange={(e) => setCourse(e.target.value)}
                            >
                                {courseData.map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        value={item.id}
                                        onClick={() => setAmount(item.fee)}
                                    >
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {courseErrorText && (
                            <Typography variant="caption" color="error">
                                {courseErrorText}
                            </Typography>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            size="small"
                            required
                            type="number"
                            fullWidth
                            id="zip"
                            label="Pincode"
                            name="zipcode"
                            autoComplete="zipcode"
                            value={zipcode}
                            error={!!zipErrorText}
                            helperText={zipErrorText}
                            onChange={(e) => setZipcode(e.target.value)}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                // defaultValue={1}
                                value={feeAmount}
                            >
                                <FormControlLabel
                                    id="female"
                                    value={"full_payment"}
                                    control={<Radio />}
                                    label="Full Payment"
                                    onChange={(e) =>
                                        feeAmountSet(e.target.value)
                                    }
                                />
                                <FormControlLabel
                                    id="male"
                                    value={"partial_payment"}
                                    control={<Radio />}
                                    label="Partial Payment"
                                    onChange={(e) => {
                                        feeAmountSet(e.target.value);
                                        setAmount("");
                                    }}
                                />
                            </RadioGroup>
                        </FormControl>
                        {feeErrorText && (
                            <Typography variant="caption" color="error">
                                {feeErrorText}
                            </Typography>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        {feeAmount == "full_payment" ? (
                            <Box>
                                <FormControl fullWidth>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="fullpayment"
                                        label="Full Payment"
                                        name="fullpayment"
                                        size="small"
                                        value={amount}
                                        error={!!amountError}
                                        helperText={amountError}
                                        onChange={(e) =>
                                            setAmount(e.target.value)
                                        }
                                    />
                                </FormControl>
                            </Box>
                        ) : (
                            <Box>
                                <FormControl fullWidth>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="partials_payment_amount"
                                        label="Partials Payment Amount"
                                        name="partials_payment_amount"
                                        size="small"
                                        value={amount}
                                        error={!!amountError}
                                        helperText={amountError}
                                        onChange={(e) =>
                                            setAmount(e.target.value)
                                        }
                                    />
                                </FormControl>
                            </Box>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <DateTime date={date} setDate={setDate} />
                    </Grid>

                    <Grid item xs={8} sm={12}>
                        <TextField
                            variant="outlined"
                            required
                            type="comment"
                            fullWidth
                            id="comment"
                            size="small"
                            label="Add Comment"
                            name="comment"
                            autoComplete="comment"
                            multiline
                            minRows={3}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </Grid>

                    <Grid item md={12}>
                        <Box sx={{ textAlign: "right" }}>
                            <Button
                                type="submit"
                                // fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={onSubmit}
                                disableElevation
                            >
                                Submit
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
