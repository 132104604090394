import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function SuccessFail() {
	return (
		<Card sx={{ minWidth: 275, maxWidth: 420, mx: 'auto', my: 4, py: 2 }}>
			<CardContent>
				<Typography
					sx={{ fontSize: 14 }}
					color="text.secondary"
					gutterBottom
				>
					Congratulation!
				</Typography>
				<Typography variant="h5" component="div"></Typography>
				<Typography variant="body2">
					Your details have been submitted successfully! Our representative will get back to you shortly.
				</Typography>
			</CardContent>
			<CardActions sx={{justifyContent: 'end'}}>
				<a href="https://www.tradinggamestrong.com/">
                    <Button size="small" variant="contained" color={'primary'} disableElevation>Learn More about Trading Game Strong</Button>
                </a>
			</CardActions>
		</Card>
	);
}
