import React, { useState } from "react";
import { useRef } from "react";
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import { Box, Typography, Card, CardContent, IconButton } from "@mui/material";

import TextField from "@mui/material/TextField";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
import UserResources from "../api/UserResources";
import { useSelector } from "react-redux";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
// import LoadingButton from "@mui/lab/LoadingButton";

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

// const rows = [
//     {
//         name: "Ajay Mathur",
//         leadID: 23,
//         contact: 12344444423,
//         email: "aj@example.com",
//         status: "Call Today 3:30 PM",
//     },
//     {
//         name: "Seema Rajput",
//         leadID: 24,
//         contact: 12344444423,
//         email: "seema.ra@example.com",
//         status: "No Response",
//     },
// ];

export default function Profile() {
    // const user = useSelector((state) => state.user.userInfo);
    const formRef = useRef();
    const userEmail = useSelector((state) => state.user.userInfo.email);
    const userID = useSelector((state) => state.user.userInfo.id);
    const userDetails = useSelector((state) => state.user.userInfo);

    const [open, setOpen] = React.useState(false);
    // const [age, setAge] = React.useState("");
    console.log("userDetails", userDetails);

    const [loading, setLoading] = useState(false);
    const [message, messageSet] = useState("");
    const [name, setName] = useState(userDetails.name);
    const [phone, setPhone] = useState(userDetails.phone);
    const [extention, setExtention] = useState(userDetails?.extension_no || "");

    // const handleChange = (event) => {
    //     setAge(event.target.value);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    const updateProfile = async (e) => {
        setLoading(false);
        e.preventDefault();
        console.log("extentiopn", extention);

        try {
            const res = await UserResources.update_user_detail({
                name: name,
                phone: phone,
                id: userID,
                extension_no: extention,
            });

            if (res.status) {
                setOpen(true);
                messageSet(res.data.message);
                formRef.current.reset();
            }
        } catch (error) {
            setOpen(true);
            messageSet(error.response.data.message);
        }
        setLoading(false);
    };

    return (
		<Box>
			<Typography
				variant="h4"
				gutterBottom
				style={{ textAlign: "center" }}
			>
				Manage Profile
			</Typography>

			<Snackbar
				open={open}
				autoHideDuration={3000}
				onClose={(e) => setOpen(false)}
				message={message}
				TransitionComponent={TransitionUp}
				action={
					<IconButton
						size="small"
						aria-label="close"
						color="inherit"
						onClick={(e) => setOpen(false)}
					>
						<CloseIcon fontSize="small" />
					</IconButton>
				}
			/>

			<Grid container spacing={3} maxWidth={"sm"} margin={"auto"}>
				<Grid item xs={12} sm={12}>
					<Box
						component="form"
						onSubmit={updateProfile}
						ref={formRef}
					>
						<Card>
							<CardContent>
								<TextField
									margin="normal"
									required
									fullWidth
									id="email"
									// label="Email Address"
									name="email"
									autoComplete="email"
									autoFocus
									value={userEmail}
									disabled
								/>
								<TextField
									margin="normal"
									required
									fullWidth
									name="na,e"
									label={"Name"}
									type="text"
									id="name"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
								<TextField
									margin="normal"
									required
									fullWidth
									name="phone"
									label={"Phone Number"}
									type="text"
									id="phone"
									value={phone}
									onChange={(e) => setPhone(e.target.value)}
								/>

								<TextField
									margin="normal"
									required
									fullWidth
									name="extention"
									label={extention || "Extention Number"}
									type="text"
									id="extention"
									value={extention}
									onChange={(e) =>
										setExtention(e.target.value)
									}
								/>

								<Button
									disableElevation
									// loading={loading}
									type="submit"
									fullWidth
									variant="contained"
								>
									{loading ? "Please wait.." : "Update"}
								</Button>
							</CardContent>
						</Card>
					</Box>
				</Grid>
			</Grid>

			{/* <Dialog open={open} onClose={handleClose} minWidth={"md"} fullWidth>
                <DialogTitle>Follow Up</DialogTitle>
                <DialogContent>
                    <Box py={3}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                                Select Status
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                label="Age"
                                onChange={handleChange}
                            >
                                <MenuItem value={10}>
                                    Call Not Received
                                </MenuItem>
                                <MenuItem value={20}>Call Later</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <TextField
                        autoFocus
                        margin="dense"
                        multiline
                        id="name"
                        label="Description"
                        type="email"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions
                    sx={{ pb: 3, px: 3, justifyContent: "space-between" }}
                >
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        disableElevation
                        onClick={handleClose}
                    >
                        Update
                    </Button>
                </DialogActions>
            </Dialog> */}
		</Box>
	);
}
