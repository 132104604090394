import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useRef } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import UserResources from "../api/UserResources";
import Slide from "@mui/material/Slide";
import { useLocation } from "react-router-dom";
function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}
const defaultTheme = createTheme();

const Register = ({ isLogin }) => {
    const formRef = useRef();
    const navigate = useNavigate();
    const location = useLocation();

    const [loading, loadingSet] = useState(false);
    const [open, setOpen] = useState(false);

    const [name, nameSet] = useState(location ? location?.state?.name : "");
    const [email, emailSet] = useState(location ? location?.state?.email : "");
    const [phone, phoneSet] = useState(
        location ? location?.state?.phone : null
    );
    const [roleID, roleIDSet] = useState(location.state ? "4" : "");
    const [password, passwordSet] = useState("");
    const [message, messageSet] = useState("");
    const [success, successSet] = useState(null);
    const [extention, extentionSet] = useState("");

    // console.log(success);

    const signUp = async (e) => {
        e.preventDefault();
        loadingSet(true);
        try {
            const res = await UserResources.register(
                roleID == 2
                    ? {
                          name: name,
                          email: email,
                          phone: phone,
                          password: password,
                          password_confirmation: password,
                          role_type: roleID,
                          extension_no: extention,
                      }
                    : {
                          name: name,
                          email: email,
                          phone: phone,
                          password: password,
                          password_confirmation: password,
                          role_type: roleID,
                      }
            );
            console.log("register response", res);
            if (res.status) {
                successSet(true);
                setOpen(true);
                messageSet(res.data.message);
                navigate("/students");
            }
        } catch (error) {
            setOpen(true);
            if (error.response && error.response.status == 422) {
                if (error.response.data.errors.email) {
                    messageSet(error.response.data.errors.email[0]);
                }
                if (error.response.data.errors.phone) {
                    messageSet(error.response.data.errors.phone[0]);
                }
            }
            loadingSet(false);
        }
        loadingSet(false);
    };

    console.log(success);
    // useEffect(() => {
    //     if (!isLogin) {
    //         navigate("/login");
    //     }
    // }, [isLogin]);

    return (
        <ThemeProvider theme={defaultTheme}>
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={(e) => setOpen(false)}
                message={message}
                TransitionComponent={TransitionUp}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={(e) => setOpen(false)}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />

            <Grid container>
                <CssBaseline />
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={5}
                    component={Paper}
                    elevation={6}
                    sx={{ mx: "auto", py: 3 }}
                    square
                >
                    <Box
                        sx={{
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Typography component="h1" variant="h5">
                            Add User
                        </Typography>

                        <Box
                            component="form"
                            noValidate
                            onSubmit={signUp}
                            sx={{ mt: 1 }}
                            ref={formRef}
                        >
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                label="Name"
                                value={name}
                                name="name"
                                autoComplete="name"
                                autoFocus
                                onChange={(e) => nameSet(e.target.value)}
                            />

                            <TextField
                                value={email}
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={(e) => emailSet(e.target.value)}
                            />
                            <TextField
                                value={phone}
                                margin="normal"
                                required
                                fullWidth
                                id="phone"
                                label="Phone"
                                name="phone"
                                autoComplete="phone"
                                autoFocus
                                onChange={(e) => phoneSet(e.target.value)}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={(e) => passwordSet(e.target.value)}
                            />
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={roleID}
                                    onChange={(e) => roleIDSet(e.target.value)}
                                >
                                    <FormControlLabel
                                        id="Student"
                                        value={4}
                                        control={<Radio />}
                                        label="Student"
                                        onChange={(e) =>
                                            roleIDSet(e.target.value)
                                        }
                                    />

                                    <FormControlLabel
                                        id="Counsellor"
                                        value={2}
                                        control={<Radio />}
                                        label="Counsellor"
                                        onChange={(e) =>
                                            roleIDSet(e.target.value)
                                        }
                                    />
                                    <FormControlLabel
                                        id="Teacher"
                                        value={3}
                                        control={<Radio />}
                                        label="Teacher"
                                        onChange={(e) =>
                                            roleIDSet(e.target.value)
                                        }
                                    />
                                    <FormControlLabel
                                        id="Admin"
                                        value={1}
                                        control={<Radio />}
                                        label="Admin"
                                        onChange={(e) =>
                                            roleIDSet(e.target.value)
                                        }
                                    />
                                </RadioGroup>
                            </FormControl>
                            {roleID == 2 && (
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="extention"
                                    label="Extention"
                                    type="text"
                                    id="extention"
                                    autoComplete="current-password"
                                    onChange={(e) =>
                                        extentionSet(e.target.value)
                                    }
                                />
                            )}
                            <Button
                                type="submit"
                                fullWidth
                                disabled={loading}
                                variant="contained"
                                sx={{ mt: 3 }}
                                disableElevation
                            >
                                {loading ? "Please wait.." : "Register"}
                            </Button>

                            {/* <Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body2">
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="/login" variant="body2">
                                        {"Already have an account? Login"}
                                    </Link>
                                </Grid>
                            </Grid> */}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default Register;
