import React, { useEffect, useState, useRef } from "react";
import { Chip, Select, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
// import LoadingButton from "@mui/lab/LoadingButton";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link, useNavigate } from "react-router-dom";
import UserResources from "../api/UserResources";
import useLoadingBackdrop from "../hooks/useLoadingBackdrop";
import EditIcon from "@mui/icons-material/Edit";

import { cleanParams } from "./Helper";
import FormControl from "@mui/material/FormControl";
import UpdateUserForm from "./UpdateUserForm";
import CustomTablePaginator from "./CustomTablePaginator";
import {
    TablePagination,
    MenuItem,
    IconButton,
    InputLabel,
    Dialog,
    AppBar,
    Toolbar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import { Pagination, Select, MenuItem } from "@mui/material";
// import { useSelector } from "react-redux/es/hooks/useSelector";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Users({
    PageLabel,
    isStudent,
    isDashboard,
    isAllUsers,
}) {
    const navigate = useNavigate();
    // const userInfo = useSelector((state) => state.user.userInfo);
    const [editingUser, setEditingUser] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [userData, setUserData] = React.useState({});
    const { LoadingBackdrop } = useLoadingBackdrop();
    const [loading, setLoading] = useState(false);
    const [data, dataSet] = useState([]);
    const [paginatedData, paginatedDataSet] = useState([]);
    const [currentPage, currentPageSet] = useState(0);
    const [perPageItem, perPageItemSet] = useState(10);
    const [keyWords, setKeyWord] = useState(isStudent ? 4 : "");

    const handleOpenEditUserModal = () => {
        setOpen(true);
    };
    const handleCloseEditUserModal = () => {
        setOpen(false);
    };

    // Fetch all users
    const UserList = async (params) => {
        console.log("keywords", keyWords);
        var params = {
                page: currentPage,
                // limit: perPageItem,
                limit: perPageItem,
                role_type: keyWords,
            },
            params;

        setLoading(true);
        try {
            const res = await UserResources.user_list(params);
            // res.data.data.reverse().map((item) => {
            //     console.log("student", item);
            // });

            if (res.status) {
                // Filter students from all users if
                if (isStudent) {
                    const students = res.data.data.filter(
                        (u) => u.role_type == 4
                    );
                    // console.log("student", students);
                    dataSet(students);
                } else {
                    dataSet(res.data.data);
                }

                paginatedDataSet(res.data.paginate_data);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        UserList();
    }, [isStudent, currentPage, perPageItem, keyWords]);

    function handleChangePage(event, newpage) {
        perPageItemSet(newpage);
    }

    function handleChangeRowsPerPage(event) {
        perPageItemSet(parseInt(event.target.value));
        currentPageSet(0);
    }

    const handlePageChange = (page) => {
        currentPageSet(page);
    };

    const handlePerPageChange = (event) => {
        perPageItemSet(event.target.value);
        currentPageSet(1);
    };

    // console.log(userData)

    const TableDataRow = ({ row }) => (
        <TableRow key={row.id}>
            <TableCell>{row.id}</TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>
                <Chip
                    size="small"
                    label={
                        row.role_type == "1"
                            ? "Admin"
                            : row.role_type == "2"
                            ? "Management"
                            : row.role_type == "3"
                            ? "Teacher"
                            : row.role_type == "4"
                            ? "Student"
                            : "Unknown Role"
                    }
                    color="primary"
                />
            </TableCell>
            <TableCell>{row.email}</TableCell>
            <TableCell>{row.phone}</TableCell>
            <TableCell>
                <Box sx={{ display: "flex", gap: 1 }}>
                    {/* {JSON.stringify(row.enrollments)} */}
                    <Link to={`/enrollment/${row.id}`} state={{ data: row }}>
                        <Button
                            variant="contained"
                            disableElevation
                            size="small"
                            color="secondary"
                        >
                            View
                        </Button>
                    </Link>
                    <IconButton
                        size="small"
                        aria-label="edit"
                        color="primary"
                        sx={{ border: "1px solid #ddd" }}
                        onClick={(e) => {
                            handleOpenEditUserModal(e);
                            setEditingUser(true);
                            setUserData(row);
                        }}
                    >
                        <EditIcon fontSize="small" />
                    </IconButton>

                    {isStudent && (
                        <>
                            <Button
                                variant="contained"
                                disableElevation
                                size="small"
                                disabled={row.role_type != 4}
                                onClick={() => {
                                    navigate(`/create-enrollment/${row.id}`, {
                                        state: { data: row },
                                    });
                                }}
                            >
                                Enroll
                            </Button>
                            {!isDashboard && (
                                <a
                                    href={`https://api.tradinggamestrong.com/payment-gateway?enrollment_id=${row.id}`}
                                    target="blank"
                                >
                                    <Button
                                        variant="contained"
                                        disableElevation
                                        size="small"
                                        disabled={row.role_type != 4}
                                    >
                                        Payment
                                    </Button>
                                </a>
                            )}
                        </>
                    )}
                </Box>
            </TableCell>
        </TableRow>
    );

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 1,
                }}
            >
                <Typography variant="h4" gutterBottom>
                    {PageLabel} {!isDashboard && data.length}
                </Typography>
                {isAllUsers && (
                    <FormControl sx={{ width: "200px" }}>
                        <InputLabel id="demo-select-small-label">
                            Filter....
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={keyWords}
                            label="Search....."
                            onChange={(e) => setKeyWord(e.target.value)}
                        >
                            <MenuItem value={""}>All</MenuItem>
                            <MenuItem value={4}>Students</MenuItem>
                            <MenuItem value={2}>Counsellor</MenuItem>
                            <MenuItem value={3}>Teachers</MenuItem>
                        </Select>
                    </FormControl>
                )}
                <Button
                    disableElevation
                    size="small"
                    // loading={loading}
                    variant="outlined"
                    onClick={(e) => {
                        UserList(e);
                    }}
                >
                    Refresh
                </Button>
            </Box>

            {loading && <LoadingBackdrop />}

            {!loading && data && data.length > 0 && (
                <TableContainer component={Paper} px={30}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Phone</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isDashboard
                                ? data &&
                                  data.map((row, index) => (
                                      <TableDataRow key={index} row={row} />
                                  ))
                                : data &&
                                  data
                                      .slice(0, 3)
                                      .map((row, index) => (
                                          <TableDataRow key={index} row={row} />
                                      ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {!isDashboard && (
                // <TablePagination
                //     rowsPerPageOptions={[10, 30, 60, 90, 120, 150]}
                //     component="div"
                //     count={paginatedData.total}
                //     rowsPerPage={perPageItem}
                //     page={currentPage}
                //     onPageChange={handleChangePage}
                //     onRowsPerPageChange={handleChangeRowsPerPage}
                // />
                <CustomTablePaginator
                    count={paginatedData.total}
                    rowsPerPage={perPageItem}
                    page={currentPage}
                    onPageChange={(newPage) => {
                        currentPageSet(newPage);
                    }}
                    onChangeRowsPerPage={(newRowsPerPage) => {
                        perPageItemSet(parseInt(newRowsPerPage));
                        currentPageSet(0);
                    }}
                />
            )}

            <Dialog
                fullScreen
                open={open}
                onClose={handleCloseEditUserModal}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseEditUserModal}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>

                        <Typography
                            sx={{ ml: 2, flex: 1, textTransform: "capitalize" }}
                            variant="h6"
                            component="div"
                        >
                            Edit - {userData.name}
                        </Typography>
                        <Button
                            autoFocus
                            color="inherit"
                            onClick={handleCloseEditUserModal}
                        >
                            Close
                        </Button>
                    </Toolbar>
                </AppBar>
                <UpdateUserForm
                    editingUser={editingUser}
                    userData={userData}
                    handleCloseEditUserModal={handleCloseEditUserModal}
                    onUpdate={UserList}
                />
            </Dialog>
        </>
    );
}
