import React from "react";
import { Box, Grid, Card, CardContent } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux/es/hooks/useSelector";
import UserResources from "../api/UserResources";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
        borderBottom: 0,
    },
    "&:before": {
        display: "none",
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function MyClasses() {
    const userInfo = useSelector((state) => state.user.userInfo);
    const [expanded, setExpanded] = React.useState("panel1");
    const [data, setData] = React.useState([]);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const fetchPayment = async () => {
        try {
            const get_classroom_list = await UserResources.get_classroom_list({
                student_id: userInfo.id,
            });
            console.log("get_classroom_list", get_classroom_list.data.data);
            setData(get_classroom_list.data.data);
        } catch (error) {}
    };
    React.useEffect(() => {
        fetchPayment();
    }, []);

    return (
        <>
            <Grid container spacing={3} maxWidth={"lg"}>
                <Grid item sm={6} xs={12}>
                    {data.map((item) => {
                        return (
                            <Card elevation={0} sx={{ mt: 2 }}>
                                <CardContent>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            borderBottom: "1px solid #ddd",
                                            py: 2,
                                        }}
                                    >
                                        <Typography color="text.secondary" fontSize={12}>
                                            Course Name
                                        </Typography>
                                        <Typography>{item.course.name}</Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            borderBottom: "1px solid #ddd",
                                            py: 2,
                                        }}
                                    >
                                        <Typography color="text.secondary" fontSize={12}>
                                            Teacher
                                        </Typography>
                                        <Typography>{item.teacher.name}</Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            borderBottom: "1px solid #ddd",
                                            py: 2,
                                        }}
                                    >
                                        <Typography color="text.secondary" fontSize={12}>
                                            Course Duration
                                        </Typography>
                                        <Typography>
                                            {item.course.duration_in_months} Months
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            py: 2,
                                        }}
                                    >
                                        <Typography color="text.secondary" fontSize={12}>
                                            Course Fee
                                        </Typography>
                                        <Typography>{item.course.fee}</Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        );
                    })}
                </Grid>
                <Grid item sm={6} xs={12}></Grid>
            </Grid>
        </>
    );
}
