import React from "react";
import { Box, Grid, Typography, Card, CardContent, Chip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux/es/hooks/useSelector";
import FollowUpModal from "./FollowUpModal";

export default function PaymentSingle() {
    const userInfo = useSelector((state) => state.user.userInfo);
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [status, statusSet] = React.useState("");
    const location = useLocation();
    const [leadID, setLeadId] = React.useState("");
    const { data } = location.state || {};

    const isLeadDetails = location.state.isLeadDetails || false;
    const isAdmin = userInfo.role_type;

    console.log("isLeadDetails", data);

    const handleChange = (event) => {
        statusSet(event.target.value);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Grid container spacing={3} maxWidth={"lg"}>
                <Grid item sm={6} xs={12}>
                    <Card elevation={1}>
                        <CardContent>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    borderBottom: "1px solid #ddd",
                                    py: 2,
                                }}
                            >
                                <Typography
                                    color="text.secondary"
                                    fontSize={12}
                                >
                                    Name
                                </Typography>
                                <Typography>{data.name || "NA"}</Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    borderBottom: "1px solid #ddd",
                                    py: 2,
                                }}
                            >
                                <Typography
                                    color="text.secondary"
                                    fontSize={12}
                                >
                                    Email
                                </Typography>
                                <Typography>{data.email || "NA"}</Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    borderBottom: "1px solid #ddd",
                                    py: 2,
                                }}
                            >
                                <Typography
                                    color="text.secondary"
                                    fontSize={12}
                                >
                                    Gender
                                </Typography>
                                <Typography>{data.gender || "NA"}</Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    borderBottom: "1px solid #ddd",
                                    py: 2,
                                }}
                            >
                                <Typography
                                    color="text.secondary"
                                    fontSize={12}
                                >
                                    Phone Number
                                </Typography>
                                <Typography>{data.phone || "NA"}</Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    borderBottom: "1px solid #ddd",
                                    py: 2,
                                }}
                            >
                                <Typography
                                    color="text.secondary"
                                    fontSize={12}
                                >
                                    State
                                </Typography>
                                <Typography>{data.state || "NA"}</Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    borderBottom: "1px solid #ddd",
                                    py: 2,
                                }}
                            >
                                <Typography
                                    color="text.secondary"
                                    fontSize={12}
                                >
                                    City
                                </Typography>
                                <Typography>{data.city || "NA"}</Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    borderBottom: "1px solid #ddd",
                                    py: 2,
                                }}
                            >
                                <Typography
                                    color="text.secondary"
                                    fontSize={12}
                                >
                                    PIN Code
                                </Typography>
                                <Typography>{data.pin_code || "NA"}</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Card elevation={1}>
                        <CardContent>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    borderBottom: "1px solid #ddd",
                                    py: 2,
                                }}
                            >
                                <Typography
                                    color="text.secondary"
                                    fontSize={12}
                                >
                                    Course
                                </Typography>
                                <Typography>
                                    {(data.course && data.course.name) || "NA"}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    borderBottom: "1px solid #ddd",
                                    py: 2,
                                }}
                            >
                                <Typography
                                    color="text.secondary"
                                    fontSize={12}
                                >
                                    Fee
                                </Typography>
                                <Typography>
                                    {(data.course && data.course.fee) || "NA"}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    borderBottom: "1px solid #ddd",
                                    py: 2,
                                }}
                            >
                                <Typography
                                    color="text.secondary"
                                    fontSize={12}
                                >
                                    Duration in months
                                </Typography>
                                <Typography>
                                    {(data.course &&
                                        data.course.duration_in_months) ||
                                        "NA"}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    borderBottom: "1px solid #ddd",
                                    py: 2,
                                }}
                            >
                                <Typography
                                    color="text.secondary"
                                    fontSize={12}
                                >
                                    Payment Made
                                </Typography>
                                <Typography>
                                    {data.payment_type || "NA"}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    borderBottom: "1px solid #ddd",
                                    py: 2,
                                }}
                            >
                                <Typography
                                    color="text.secondary"
                                    fontSize={12}
                                >
                                    Amount
                                </Typography>
                                <Typography>{data.amount || "NA"}</Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    borderBottom: "1px solid #ddd",
                                    py: 2,
                                }}
                            >
                                <Typography
                                    color="text.secondary"
                                    fontSize={12}
                                >
                                    Payment Date
                                </Typography>
                                <Typography>
                                    {data.payment_date || "NA"}
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Card elevation={1}>
                        <CardContent>
                            <Typography
                                // color="text.secondary"
                                // fontSize={12}
                                variant="h5"
                            >
                                Payment Details
                            </Typography>
                            {data.payments.length === 0 ? (
                                <h4>No Payment Details Found </h4>
                            ) : (
                                data.payments.map((p, k) => (
                                    <div key={k}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                borderBottom: "1px solid #ddd",
                                                py: 2,
                                            }}
                                        >
                                            Payment {k + 1}
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                borderBottom: "1px solid #ddd",
                                                py: 2,
                                            }}
                                        >
                                            <Typography
                                                color="text.secondary"
                                                fontSize={12}
                                            >
                                                Paid
                                            </Typography>

                                            <Typography>{p.amount}</Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                borderBottom: "1px solid #ddd",
                                                py: 2,
                                            }}
                                        >
                                            <Typography
                                                color="text.secondary"
                                                fontSize={12}
                                            >
                                                Payment Method
                                            </Typography>

                                            <Typography>{p.method}</Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                borderBottom: "1px solid #ddd",
                                                py: 2,
                                            }}
                                        >
                                            <Typography
                                                color="text.secondary"
                                                fontSize={12}
                                            >
                                                Payment Status
                                            </Typography>

                                            <Typography>{p.status}</Typography>
                                        </Box>
                                    </div>
                                ))
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}
