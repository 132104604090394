// userSlice.js

import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: "user",
    initialState: {
        // userType: "1",
        loggedIn: false,
        userInfo: {},
    },
    reducers: {
        login: (state, action) => {
            state.loggedIn = action.payload;
        },

        setUserInfo: (state, action) => {
            state.userInfo = action.payload;
        },
        logout: (state) => {
            state.userInfo = {}; // Reset persisted data to null on logout
        },
    },
});

// export const { setUserType, login, setUserInfo, logout } = userSlice.actions;
export const { login, setUserInfo, logout } = userSlice.actions;

export default userSlice.reducer;
