import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import React from "react";
import {
    Amazing,
    Classes,
    Earning,
    Email,
    Experience,
    Name,
    OfflineClasses,
    OnlineOrRecordedClasses,
    PhoneNumber,
    Profession,
    Submit,
} from "../components/LeadFields";

import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import StepContent from "@mui/material/StepContent";

const Leads = (value) => {
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [profession, setProfession] = React.useState("");
    const [earning, setEarning] = React.useState("");
    const [experience, setExperience] = React.useState("");
    const [amazing, setAmazing] = React.useState("");
    const [classes, setClasses] = React.useState("");
    const [onlineorrecordeclasses, setOnlineorrecordeclasses] =
        React.useState("");
    const [offlineclass, setOfflineclass] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [submit, setSubmit] = React.useState("");

    // const handlSubmit=(e)=>{
    //   e.preventDefault();
    //   alert(earning)

    // }

    const steps = [
        {
            label: "Name",
            inputType: <Name name={name} setName={setName} />,
            value: { name },
        },
        {
            label: "Email",
            inputType: <Email email={email} setEmail={setEmail} />,
            value: { email },
        },
        {
            label: "Profession",
            inputType: (
                <Profession
                    profession={profession}
                    setProfession={setProfession}
                />
            ),
            value: { profession },
        },
        {
            label: "Earning",
            inputType: <Earning earning={earning} setEarning={setEarning} />,
            value: { earning },
        },
        {
            label: "Experience",
            inputType: (
                <Experience
                    experience={experience}
                    setExperience={setExperience}
                />
            ),
            value: { experience },
        },
        {
            label: "Amazing",
            inputType: <Amazing amazing={amazing} setAmazing={setAmazing} />,
            value: { amazing },
        },
        {
            label: "Classes",
            inputType: <Classes classes={classes} setClasses={setClasses} />,
            value: { classes },
        },
        {
            label: "Online OR Recorded Classes",
            inputType: (
                <OnlineOrRecordedClasses
                    onlineorrecordeclasses={onlineorrecordeclasses}
                    setOnlineorrecordeclasses={setOnlineorrecordeclasses}
                />
            ),
            value: { onlineorrecordeclasses },
        },
        {
            label: "Offline Classes",
            inputType: (
                <OfflineClasses
                    offlineclass={offlineclass}
                    setOfflineclass={setOfflineclass}
                />
            ),
            // value: { offlineclass },
        },
        {
            label: "Phone Number",
            inputType: <PhoneNumber phone={phone} setPhone={setPhone} />,
            value: { phone },
        },
        {
            label: "Submit",
            inputType: <Submit submit={submit} setSubmit={setSubmit} />,
            value: { submit },
        },
    ];

    console.log("main ----");

    const [activeStep, setActiveStep] = React.useState(0);
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <>
            <Box sx={{ maxWidth: "sm" }}>
                {/* <Stepper activeStep={activeStep} */}
                <Stepper activeStep={1} orientation="vertical">
                    {steps &&
                        steps.map((step, index) => {
                            // if (isStepFailded(index)) {
                            console.log("dnndfljlnk");
                            if (index > 0 && steps[index - 1].value) {
                                <Step key={step.label}>
                                    {console.log("v---->", step.value)}
                                    {console.log("vks")}
                                    <StepLabel
                                        optional={
                                            index === 0 ? (
                                                <Typography
                                                    variant="caption"
                                                    color="error"
                                                >
                                                    Field is required
                                                </Typography>
                                            ) : null
                                        }
                                    >
                                        {step.label}
                                        {step.name}
                                    </StepLabel>
                                    <StepContent>
                                        <Box>{step.inputType}</Box>
                                        <Box sx={{ mb: 2 }}>
                                            <div>
                                                <Button
                                                    disabled={index === 0}
                                                    onClick={handleBack}
                                                    sx={{
                                                        mt: 1,
                                                        mr: 1,
                                                    }}
                                                    disableElevation
                                                >
                                                    Back
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    onClick={handleNext}
                                                    sx={{
                                                        mt: 1,
                                                        mr: 1,
                                                    }}
                                                    disableElevation
                                                >
                                                    {index ===
                                                        steps.length - 1 &&
                                                    step.value !== null
                                                        ? "Finish"
                                                        : "Continue"}
                                                </Button>
                                            </div>
                                        </Box>
                                    </StepContent>
                                </Step>;
                            }
                            // }
                        })}
                </Stepper>
                {activeStep === steps.length && (
                    <Paper square elevation={0} sx={{ p: 3 }}>
                        <Typography>
                            Great! Your answers have been submitted
                            successfully!
                        </Typography>
                        {/* <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button> */}
                    </Paper>
                )}
            </Box>
        </>
    );
};

export default Leads;
