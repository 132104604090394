import React from "react";

import Avatar from "@mui/material/Avatar";
// import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
// import Person2 from "@mui/icons-material/Folder";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Box, Card, CardActions, CardContent, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import dateFormat from "dateformat";
import { Edit, Person2 } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import { TablePagination } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useRef } from "react";

import UserResources from "../api/UserResources";
import useLoadingBackdrop from "../hooks/useLoadingBackdrop";

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

export default function AddTeacher() {
    const formRef = useRef();

    // Editing existing teacher
    const [edit, setEdit] = React.useState(false);
    const [prefilledData, setPrefilledData] = React.useState({});
    const [updatingTeacher, updatingTeacherSet] = React.useState(false);

    const { LoadingBackdrop } = useLoadingBackdrop();
    const [loading, setLoading] = React.useState(false);
    const [addingTeacher, addingTeacherSet] = React.useState(false);
    const [data, dataSet] = React.useState([]);
    const [paginatedData, paginatedDataSet] = React.useState([]);
    const [pg, setpg] = React.useState(1);
    const [rpg, setrpg] = React.useState(10);
    const [name, setName] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [confirmpassord, setConfirmPassword] = React.useState("");
    const [openSnackbar, openSnackbarSet] = React.useState(false);
    const [message, setMessage] = React.useState("");

    console.log(prefilledData);

    React.useEffect(() => {
        setName(prefilledData.name);
        setEmail(prefilledData.email);
        setPhone(prefilledData.phone);
    }, [edit, prefilledData.id]);

    // Fetch all users
    const UserList = async (params) => {
        var params = {
                page: pg,
                limit: rpg,
                role_type: 3,
            },
            params;

        setLoading(true);
        try {
            const res = await UserResources.user_list(params);
            console.log("teacher list", res.data.data);
            // dataSet(res.data.data);

            if (res.data.status) {
                // Filter Teachers from all users

                const Teachers = res.data.data.filter((u) => u.role_type == "3");

                dataSet(Teachers);

                paginatedDataSet(res.data.paginate_data);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    React.useEffect(() => {
        UserList();
    }, [rpg, pg, edit]);

    function handleChangePage(event, newpage) {
        setpg(newpage);
    }

    function handleChangeRowsPerPage(event) {
        setrpg(parseInt(event.target.value));
        setpg(0);
    }

    const postTeacher = async (params) => {
        addingTeacherSet(true);

        var params = {
                name: name,
                email: email,
                phone: phone,
                password: password,
                password_confirmation: confirmpassord,
                role_type: 3,
            },
            params;
        try {
            const res = await UserResources.register(params);

            if (res.status) {
                // formRef.current.reset();
                UserList();
                setName("");
                setEmail("");
                setPhone("");
                setPassword("");
                setConfirmPassword("");
                openSnackbarSet(true);
                setMessage("Teacher created successfully!");
            }
        } catch (error) {
            console.log(error);
            openSnackbarSet(true);
            setMessage("Something went wrong! Please try later.");
        }
        addingTeacherSet(false);
    };

    const EditTeacher = async (params) => {
        updatingTeacherSet(true);
        var params = {
                name: name,
                email: email,
                phone: phone,
                id: prefilledData.id,
            },
            params;

        try {
            const res = await UserResources.update_user_detail(params);

            if (res.status) {
                UserList();
                setEdit(false);
                setPrefilledData({});
                openSnackbarSet(true);
                setMessage("Course created successfully!");
            }
        } catch (error) {
            console.log(error);
            openSnackbarSet(true);
            setMessage("Something went wrong! Please try later.");
        }
        updatingTeacherSet(false);
    };

    return (
        <Box>
            {(loading || updatingTeacher || addingTeacher) && <LoadingBackdrop />}
            <Grid container spacing={3} margin={"auto"}>
                <Grid item sm={12}>
                    <Button
                        variant="contained"
                        size="small"
                        disableElevation
                        color="primary"
                        onClick={(e) => {
                            setEdit(false);
                        }}
                    >
                        + Add Teacher
                    </Button>
                </Grid>
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={(e) => openSnackbarSet(false)}
                    message={message}
                    TransitionComponent={TransitionUp}
                    action={
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={(e) => openSnackbarSet(false)}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />

                <Grid item xs={12} sm={6}>
                    <Card
                        component={"form"}
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (edit) {
                                EditTeacher();
                            } else {
                                postTeacher();
                            }
                        }}
                        ref={formRef}
                    >
                        <CardContent>
                            <Typography variant="h5">
                                {edit ? "Update Teacher" : "Add New Teacher"}
                            </Typography>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                label="Teacher Name"
                                name="name"
                                autoComplete="Teacher Name"
                                value={name}
                                autoFocus
                                onChange={(e) => setName(e.target.value)}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="Email"
                                value={email}
                                autoFocus
                                onChange={(e) => setEmail(e.target.value)}
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="phone"
                                label="Phone"
                                name="phone"
                                autoComplete="Phone"
                                autoFocus
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />

                            {!edit && (
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="password"
                                    label="Password"
                                    name="password"
                                    type="password"
                                    autoComplete="Password"
                                    value={password}
                                    autoFocus
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            )}

                            {!edit && (
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="password"
                                    label="Confirm Passwword"
                                    name="confirmpassord"
                                    type="password"
                                    autoComplete="Confirm Password"
                                    value={confirmpassord}
                                    autoFocus
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            )}
                        </CardContent>
                        <CardActions>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disableElevation
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {edit ? "Update Teacher" : "Add Teacher"}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardContent>
                            <List dense sx={{ maxHeight: "350px", overflowY: "auto" }}>
                                {data &&
                                    data.length > 0 &&
                                    data.map((item, index) => (
                                        <ListItem
                                            secondaryAction={
                                                <>
                                                    <IconButton
                                                        edge="end"
                                                        color="primary"
                                                        aria-label="edit"
                                                        onClick={(e) => {
                                                            setEdit(true);
                                                            setPrefilledData(item);
                                                        }}
                                                    >
                                                        <Edit />
                                                    </IconButton>
                                                    {/* <IconButton
                                                        edge="end"
                                                        aria-label="delete"
                                                        color="secondary"
                                                        // onClick={handleClickOpen}
                                                    >
                                                        <DeleteForeverIcon />
                                                    </IconButton> */}
                                                </>
                                            }
                                        >
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <Person2 />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={item.name}
                                                secondary={dateFormat(
                                                    item.created_at,
                                                    "dd mmm, yyyy",
                                                )}
                                            />
                                            <ListItemText
                                                primary={item.email}
                                                secondary={item.phone}
                                            />
                                        </ListItem>
                                    ))}
                            </List>
                        </CardContent>
                        <CardActions>
                            <TablePagination
                                rowsPerPageOptions={[10, 30, 60, 90, 120, 150]}
                                component="div"
                                count={paginatedData.total}
                                rowsPerPage={rpg}
                                page={pg}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}
