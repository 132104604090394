import * as React from "react";
import Typography from "@mui/material/Typography";
import { Button, Card, CardContent, Grid } from "@mui/material";
import dateformat from "dateformat";
import UserResources from "../api/UserResources";
import { useSelector } from "react-redux";

export default function Attendance(data) {
    const userInfo = useSelector((state) => state.user.userInfo);
    const [list, setList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [details, setDetails] = React.useState({});
    const [markStatus, setMarkStatus] = React.useState(false);
    const [markLoading, setMarkLoading] = React.useState(false);

    const Attandence = async (data) => {
        setMarkLoading(true);
        try {
            const response = await UserResources.markAttandence(data);
            if (response.data.message == "success") {
                // attandenceList();
                setMarkStatus(true);
                attandenceList();
                getDetails();
            }
        } catch (error) {
            console.log(error);
            setMarkStatus(false);
        }
        setMarkLoading(false);
    };

    const getDetails = async () => {
        try {
            const response = await UserResources.getUserInfo();
            setDetails(response.data.data.today_attendance);
        } catch (error) {
            console.log(error);
        }
    };

    React.useEffect(() => {
        getDetails();
    }, [markStatus]);
    const attandenceList = async () => {
        setLoading(true);
        try {
            const response = await UserResources.allAttandance({
                user_id: userInfo.id,
            });
            setList(response.data.data);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    React.useEffect(() => {
        attandenceList();
    }, []);
    return (
        !loading && (
            <>
                {!markLoading && (
                    <Card sx={{ maxWidth: "sm", mb: 4 }} elevation={0}>
                        <CardContent
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            {!details?.check_in ? (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disableElevation
                                    onClick={() =>
                                        Attandence({
                                            user_id: userInfo.id,
                                            check_in: dateformat(
                                                Date.now(),
                                                "yyyy-mm-dd HH:MM:ss"
                                            ),
                                        })
                                    }
                                >
                                    Check In
                                </Button>
                            ) : details?.check_in && !details?.check_out ? (
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    onClick={() =>
                                        Attandence({
                                            user_id: userInfo.id,
                                            check_out: dateformat(
                                                Date.now(),
                                                "yyyy-mm-dd HH:MM:ss"
                                            ),
                                            id: details?.id,
                                        })
                                    }
                                >
                                    Check Out
                                </Button>
                            ) : (
                                ""
                            )}
                        </CardContent>
                    </Card>
                )}

                <Typography variant="hr">My Attendance History</Typography>

                {list.map((item, index) => {
                    return (
                        <Card
                            elevation={0}
                            sx={{
                                maxWidth: "sm",
                                borderBottom: "1px solid #ddd",
                            }}
                            key={index}
                        >
                            <CardContent
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item sm="6">
                                        <Typography
                                            color="primary"
                                            variant="contained"
                                        >
                                            {item.check_in}
                                            {/* Check In: 12 Aug, 2023 11:15 PM */}
                                        </Typography>
                                    </Grid>
                                    <Grid item sm="6">
                                        <Typography
                                            color="primary"
                                            variant="contained"
                                        >
                                            {item.check_out}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    );
                })}
            </>
        )
    );
}
