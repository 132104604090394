import { Box, Card, CardContent, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import { useRef, useState } from "react";
import UserResources from "../api/UserResources";

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

const items = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
export default function CreateCourse() {
    const formRef = useRef();
    const [openSnackbar, openSnackbarSet] = React.useState(false);
    const [name, setName] = useState("");
    const [fee, setFee] = useState("");
    const [duration, setDuration] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const updateCourses = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const res = await UserResources.saveUpdateCourse({
                name: name,
                fee: fee,
                duration_in_months: duration,
                status: 1,
            });

            if (res.status) {
                openSnackbarSet(true);
                setMessage("Course created successfully!");
                setDuration();
                formRef.current.reset();
            }
        } catch (error) {
            console.log(error);
            openSnackbarSet(true);
            setMessage("Something went wrong! Please try later.");
        }
        setLoading(false);
    };

    return (
        <Box sx={{ p: 6 }}>
            <Typography
                variant="h4"
                gutterBottom
                style={{ textAlign: "center" }}
            >
                Create New Course
            </Typography>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={(e) => openSnackbarSet(false)}
                message={message}
                TransitionComponent={TransitionUp}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={(e) => openSnackbarSet(false)}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
            <Grid container spacing={3} maxWidth={"sm"} margin={"auto"}>
                <Grid item xs={12} sm={12}>
                    <Box
                        component={"form"}
                        onSubmit={updateCourses}
                        ref={formRef}
                    >
                        <Card>
                            <CardContent>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Course Name"
                                    name="name"
                                    autoComplete="name"
                                    autoFocus
                                    onChange={(e) => setName(e.target.value)}
                                />

                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="fee"
                                    label="Course Fee"
                                    name="fee"
                                    autoComplete="fee"
                                    autoFocus
                                    onChange={(e) => setFee(e.target.value)}
                                />

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                        Duration
                                    </InputLabel>
                                    <Select
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="duration"
                                        label="duration"
                                        name="duration"
                                        autoComplete="duration"
                                        autoFocus
                                        value={duration}
                                        onChange={(e) =>
                                            setDuration(e.target.value)
                                        }
                                    >
                                        {items.map((month, index) => (
                                            <MenuItem key={index} value={month}>
                                                {month}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    disableElevation
                                    sx={{ mt: 3, mb: 2 }}
                                    loading={loading}
                                >
                                    {loading ? "Please wait" : "Create Course"}
                                </Button>
                            </CardContent>
                        </Card>{" "}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
