import React, { useRef } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Grid, TextField, FormControlLabel, FormControl } from "@mui/material";
import UserResources from "../api/UserResources";
import { cleanParams } from "./Helper";
// import useLoadingBackdrop from "../hooks/useLoadingBackdrop";
// import Snackbar from "@mui/material/Snackbar";
// import Slide from "@mui/material/Slide";
// import { IconButton } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
// function TransitionUp(props) {
//     return <Slide {...props} direction="up" />;
// }

export default function UpdateUserForm({
    editingUser,
	userData,
	handleCloseEditUserModal,
	onUpdate,
}) {
    const formRef = useRef();
    // const [openSnackbar, openSnackbarSet] = React.useState(false);
    // const [message, messageSet] = React.useState("");
    // const { LoadingBackdrop } = useLoadingBackdrop();
	const [updatingUser, updatingUserSet] = React.useState(false);
	const [name, nameSet] = React.useState(userData.name ? userData.name : "");
	const [email, emailSet] = React.useState(userData.email ? userData.email : "");
	const [phone, phoneSet] = React.useState(userData.phone ? userData.phone : "");
	const [roleID, roleIDSet] = React.useState(userData.role_type ? userData.role_type : "");
	const [extention, extentionSet] = React.useState(userData.extension_no ? userData.extension_no : "");

	const updateUser = async (e) => {
		updatingUserSet(true);
		e.preventDefault();

		try {
			const res = await UserResources.update_user_detail(
				cleanParams({
					id: userData.id,
					name: name,
					email: email,
					phone: phone,
					role_type: roleID,
					extension_no: extention,
				})
			);

			if (res.status) {
				// openSnackbarSet(true);
				alert('User details has been updated successfully!');
				formRef.current.reset();
                handleCloseEditUserModal()
                onUpdate()
			}
		} catch (error) {
			// openSnackbarSet(true);
			alert(error.response.data.message);
		}
		updatingUserSet(false);
	};

	return (
		<Box mt={4}>
            {/* {updatingUser && <LoadingBackdrop />} */}
			<Grid container>
				<Grid
					item
					xs={12}
					sm={8}
					md={5}
					component={Paper}
					elevation={6}
					sx={{ mx: "auto", py: 3 }}
					square
				>
					<Box
						sx={{
							mx: 4,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Typography component="h1" variant="h5">
							{editingUser ? "Edit" : "Add"} User
						</Typography>

						<Box
							component="form"
							noValidate
							onSubmit={updateUser}
							sx={{ mt: 1 }}
							ref={formRef}
						>
							<TextField
								margin="normal"
								required
								fullWidth
								id="name"
								label="Name"
								value={name}
								name="name"
								autoComplete="name"
								autoFocus
								onChange={(e) => nameSet(e.target.value)}
							/>

							<TextField
								value={email}
								margin="normal"
								required
								fullWidth
								id="email"
								label="Email Address"
								name="email"
								autoComplete="email"
								autoFocus
								onChange={(e) => emailSet(e.target.value)}
							/>
							<TextField
								value={phone}
								margin="normal"
								required
								fullWidth
								id="phone"
								label="Phone"
								name="phone"
								autoComplete="phone"
								autoFocus
								onChange={(e) => phoneSet(e.target.value)}
							/>
							{/* <TextField
								margin="normal"
								required
								fullWidth
								name="password"
								label="Password"
								type="password"
								id="password"
								autoComplete="current-password"
								onChange={(e) => passwordSet(e.target.value)}
							/> */}
							<FormControl>
								<RadioGroup
									row
									aria-labelledby="demo-row-radio-buttons-group-label"
									name="row-radio-buttons-group"
									// value={roleID}
									value={roleID}
									onChange={(e) => roleIDSet(e.target.value)}
								>
									<FormControlLabel
										id="Student"
										value={4}
										control={<Radio />}
										label="Student"
										onChange={(e) =>
											roleIDSet(e.target.value)
										}
									/>

									<FormControlLabel
										id="Counsellor"
										value={2}
										control={<Radio />}
										label="Counsellor"
										onChange={(e) =>
											roleIDSet(e.target.value)
										}
									/>
									<FormControlLabel
										id="Teacher"
										value={3}
										control={<Radio />}
										label="Teacher"
										onChange={(e) =>
											roleIDSet(e.target.value)
										}
									/>
									<FormControlLabel
										id="Admin"
										value={1}
										control={<Radio />}
										label="Admin"
										onChange={(e) =>
											roleIDSet(e.target.value)
										}
									/>
								</RadioGroup>
							</FormControl>
							{roleID == 2 && (
								<TextField
									margin="normal"
									required
									fullWidth
									name="extention"
									label="Extention"
									type="text"
									id="extention"
									autoComplete="current-password"
									onChange={(e) =>
										extentionSet(e.target.value)
									}
								/>
							)}
							<Button
								type="submit"
								fullWidth
								disabled={updatingUser}
								variant="contained"
								sx={{ mt: 3 }}
								disableElevation
							>
								{updatingUser ? "Please wait.." : "Update"}
							</Button>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}
