import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function ErrorPage() {
  return (
    <>
      <section class="page_404">
        <div
          class="container"
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div>
            <div class="four_zero_four_bg">
              <h1 class="text-center ">404</h1>
            </div>

            <div class="contant_box_404">
              <h3 class="h2">Look like you're lost</h3>

              <p>the page you are looking for not avaible!</p>

              <Link to="/" class="link_404">
                <Button variant="contained">Go to home</Button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
