import React from 'react'
import {
    // Typography,
    // Dialog,
    // DialogTitle,
    // DialogContent,
    // DialogContentText,
    // DialogActions,
    // Tooltip,
    IconButton,
    Table,
    TableRow,
    TableBody,
    TablePagination,
    // Box,
    // Button,
    // Grid,
    // MenuItem
} from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";

// import makeStyles from '@mui/styles/makeStyles';

// const useStyles = makeStyles(theme => ({
//     root: {
//         flexShrink: 0,
//         marginLeft: theme.spacing(2.5)
//     }
// }));

function TablePaginationActions(props) {
    // const classes = useStyles();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = event => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = event => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div style={{flexShrink: 0,}}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
                size="large">
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                        <FirstPageIcon />
                    )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
                size="large">
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                        <KeyboardArrowLeft />
                    )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
                size="large">
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                        <KeyboardArrowRight />
                    )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
                size="large">
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                        <LastPageIcon />
                    )}
            </IconButton>
        </div>
    );
}

export default function CustomTablePaginator(props)
{
    return (
        <Table className={props.additionalClass ? props.additionalClass : ''}>
            <TableBody>
                <TableRow>
                    <TablePagination
                        align='right'
                        rowsPerPageOptions={[
                            1,
                            2,
                            5,
                            10,
                            15,
                            25,
                            50,
                            100,
                            150,
                            200,
                            250,
                            300,
                            // { label: "All", value: -1 }
                        ]}
                        count={props.count}
                        rowsPerPage={props.rowsPerPage}
                        page={props.page}
                        //labelDisplayedRows= {page} {paginationData.total}
                        //labelRowsPerPage= "Rows per page:"
                        SelectProps={{
                            inputProps: { "aria-label": "rows per page" }
                        }}
                        onPageChange={(e, v) => {
                            props.onPageChange(v)
                        }}
                        onRowsPerPageChange={e => {
                            props.onChangeRowsPerPage(e.target.value)
                        }}
                        ActionsComponent={TablePaginationActions}
                    />
                </TableRow>
            </TableBody>
        </Table>
    );
}
