import axios from "axios";
import config from "../config";
import store from "../store/store";
import { login, logout } from "../store/userSlice";

const errorCodes = [400, 401, 404, 422, 500];

const HOST_URL = config.api_url;
console.log({ "HOST_URL √": HOST_URL });

const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
};

const instance = axios.create({
    baseURL: HOST_URL,
    headers: headers,
});

instance.interceptors.request.use(
    async function (conf) {
        const state = store.getState();

        const userToken = state.user.userInfo.plain_text_token;

        // console.log("userToken", state.user.loggedIn);

        const params = conf.params || {};

        if (conf.params) {
            params = conf.params;
        }
        if (userToken) {
            conf.headers.Authorization = `Bearer ${userToken}`;
        }

        conf.params = params;

        return conf;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => response,
    (error) => {
        const { status } = error.response;

        console.log(status);

        // console.log("Error ==>", error.response.status, error.response.data.message);

        if (errorCodes.includes(status)) {
            switch (status) {
                case 400:
                    // Handle 400 error
                    console.log("400 error:", error);
                    break;
                case 401:
                    // Handle 401 error
                    console.log("401 error:", error);
                    store.dispatch(logout());
                    store.dispatch(login(false));
                    localStorage.clear();
                    window.location.href = "/login";
                    break;
                case 404:
                    // Handle 404 error
                    console.log("404 error:", error);
                    break;
                case 422:
                    // Handle 422 error
                    console.log("422 error:", error);
                    // Redirect to the login page or handle the error
                    // window.location.href = "/login";
                    console.log(error.response.data.message);
                    break;
                case 500:
                    // Handle 500 error
                    console.log("500 error:", error);
                    break;
                default:
                    // Handle other error codes
                    console.log("Unknown error:", error);
                    break;
            }
        }

        return Promise.reject(error);
    }
);

export default instance;
