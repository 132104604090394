import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { LocationOn } from "@mui/icons-material";

export const Name = ({ name, setName }) => {
    return (
        <>
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <Typography variant="h5" gutterBottom>
                    First tell your name
                </Typography>
                <Input
                    placeholder="Type your answer here..."
                    startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                    }
                    variant="outlined"
                    required
                    // error
                    type="name"
                    fullWidth
                    id="name"
                    name="name"
                    autoComplete="name"
                    autoFocus
                    value={name}
                    // helperText={"error field"}
                    onChange={(e) => setName(e.target.value)}
                />
            </FormControl>
        </>
    );
};

export const Email = ({ email, setEmail }) => {
    return (
        <>
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <Typography variant="h5" gutterBottom>
                    What's your email?
                </Typography>
                <Typography variant="body1" gutterBottom>
                    We’ll only email you relevant information your query and
                    offers.
                </Typography>
                <Input
                    placeholder="name@example.com"
                    startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                    }
                    variant="outlined"
                    required
                    type="email"
                    fullWidth
                    id="email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </FormControl>
        </>
    );
};

export const Profession = ({ profession, setProfession }) => {
    return (
        <>
            <Typography variant="h5" gutterBottom>
                What's your Profession?
            </Typography>

            <Box sx={{ display: "flex" }}>
                <FormControl
                    sx={{ m: 3 }}
                    component="fieldset"
                    variant="standard"
                >
                    <RadioGroup
                        aria-labelledby="profession-radio-buttons-group-label"
                        value={profession}
                        name="profession"
                        onChange={(e) => setProfession(e.target.value)}
                        defaultValue={profession}
                    >
                        <FormControlLabel
                            value="Student"
                            control={<Radio />}
                            label="Student"
                        />
                        <FormControlLabel
                            value="Unemployed"
                            control={<Radio />}
                            label="Unemployed"
                        />

                        <FormControlLabel
                            value="SalariedEmoloyee"
                            control={<Radio />}
                            label="SalariedEmoloyee"
                        />
                        <FormControlLabel
                            value="BusinessOwner"
                            control={<Radio />}
                            label="BusinessOwner"
                        />
                        <FormControlLabel
                            value="Housewife"
                            control={<Radio />}
                            label="Housewife"
                        />
                        <FormControlLabel
                            value="Retired"
                            control={<Radio />}
                            label="Retired"
                        />
                    </RadioGroup>
                </FormControl>
            </Box>
        </>
    );
};

export const Earning = ({ earning, setEarning }) => {
    return (
        <>
            <Typography variant="h5" gutterBottom>
                What's your current Earnings per month?
            </Typography>
            <Typography variant="body1" gutterBottom>
                Everything you share will be completely discreet, only the
                assigned TGS consultant will review your information in order to
                provide you with the perfect solution to compound it.
            </Typography>
            <Box sx={{ display: "flex" }}>
                <FormControl
                    sx={{ m: 3 }}
                    component="fieldset"
                    variant="standard"
                >
                    <RadioGroup
                        aria-labelledby="earning-radio-buttons-group-label"
                        value={earning}
                        name="earning"
                        defaultValue={earning}
                        onChange={(e) => setEarning(e.target.value)}
                    >
                        <FormControlLabel
                            value="0-10000"
                            control={<Radio />}
                            label="0-10000"
                        />
                        <FormControlLabel
                            value="10000-35000"
                            control={<Radio />}
                            label="10000-35000"
                        />

                        <FormControlLabel
                            value="35000-100000"
                            control={<Radio />}
                            label="35000-100000"
                        />
                        <FormControlLabel
                            value="100000-500000"
                            control={<Radio />}
                            label="100000-500000"
                        />
                        <FormControlLabel
                            value="500000+"
                            control={<Radio />}
                            label="500000+"
                        />
                    </RadioGroup>
                </FormControl>
            </Box>
        </>
    );
};

export const Experience = ({
    experience,
    setExperience,
    experienceOptions,
    setExperienceID,
    experienceID,
}) => {
    return (
        <>
            <Typography variant="h5" gutterBottom>
                Tell us more about your past trading journey.This question is
                required.
            </Typography>
            <Typography variant="body1" gutterBottom>
                It will help us understand you better and provide the most
                efficient solution.
            </Typography>
            <Box sx={{ display: "flex" }}>
                <FormControl
                    sx={{ m: 3 }}
                    component="fieldset"
                    variant="standard"
                >
                    <RadioGroup
                        aria-labelledby="experience-radio-buttons-group-label"
                        // defaultValue="No experience"
                        // value={experience}
                        // defaultValue={experienceOptions[0].id}
                        value={experienceID}
                        name="experience"
                        // onChange={(e) => { setExperience(e.target.value);  setExperienceID(e.target.value)}}
                        onChange={(e) => {
                            const selectedOption = experienceOptions.find(
                                (option) => option.id === e.target.value
                            );
                            setExperience(selectedOption.label);
                            setExperienceID(selectedOption.id);
                        }}
                    >
                        {experienceOptions.map((option) => (
                            <FormControlLabel
                                key={option.id}
                                value={option.id}
                                control={<Radio />}
                                label={option.label}
                            />
                        ))}
                        {/* <FormControlLabel
							value="No experience"
							control={<Radio />}
							label=" No experience"
						/>
						<FormControlLabel
							value="Experienced Small Losses"
							control={<Radio />}
							label="Experienced Small Losses"
						/>

						<FormControlLabel
							value="Experienced but huge Losses"
							control={<Radio />}
							label="Experienced but huge Losses"
						/>
						<FormControlLabel
							value="Experienced with Profit/Break-even"
							control={<Radio />}
							label="Experienced with Profit/Break-even"
						/> */}
                    </RadioGroup>
                </FormControl>
            </Box>
        </>
    );
};

export const Amazing = ({ offering, setOffering }) => {
    return (
        <FormControl fullWidth sx={{ m: 1 }} variant="standard">
            <Typography variant="h5" gutterBottom>
                5 - Amazing, We are also looking for skilled and professional
                traders.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Tell us what Trading Game Strong can offer you !
            </Typography>
            <Input
                placeholder="Type your answer here..."
                h={20}
                startAdornment={
                    <InputAdornment position="start"></InputAdornment>
                }
                variant="outlined"
                required
                fullWidth
                id="amazing"
                name="amazing"
                autoComplete="amazing"
                autoFocus
                value={offering}
                onChange={(e) => setOffering(e.target.value)}
            />
        </FormControl>
    );
};

export const Classes = ({ classesType, setClassesType }) => {
    return (
        <>
            <Typography variant="h5" gutterBottom>
                5 - What type of classes are you interested in?
            </Typography>

            <Box sx={{ display: "flex" }}>
                <RadioGroup
                    aria-labelledby="classesType-radio-buttons-group-label"
                    defaultValue="No experience"
                    value={classesType}
                    name="classesType"
                    onChange={(e) => setClassesType(e.target.value)}
                >
                    <FormControl
                        sx={{ m: 3 }}
                        component="fieldset"
                        variant="standard"
                    >
                        <FormGroup>
                            <FormControlLabel
                                control={<Radio />}
                                label="Offline"
                                value="offline"
                            />
                            <FormControlLabel
                                control={<Radio />}
                                label="Online"
                                value="online"
                            />
                            <FormControlLabel
                                control={<Radio />}
                                label="Recorded Course"
                                value="recorded course"
                            />
                        </FormGroup>
                    </FormControl>
                </RadioGroup>
            </Box>
        </>
    );
};

export const OnlineOrRecordedClasses = ({
    onlineRecordedClasses,
    setOnlineRecordedClasses,
}) => {
    return (
        <>
            <Typography variant="h5" gutterBottom>
                6 -You have opted for Recorded/Online Course?
            </Typography>

            <Box sx={{ display: "flex" }}>
                <RadioGroup
                    aria-labelledby="onlineRecordedClasses-radio-buttons-group-label"
                    defaultValue="online Recorded Classes"
                    value={onlineRecordedClasses}
                    name="onlineRecordedClasses"
                    onChange={(e) => setOnlineRecordedClasses(e.target.value)}
                >
                    <FormControl
                        sx={{ m: 3 }}
                        component="fieldset"
                        variant="standard"
                    >
                        <FormGroup>
                            <FormControlLabel
                                control={<Radio />}
                                label="Mechanics of Indian Stock Market (Delhi Branch)"
                                value="Mechanics of Indian Stock Market (Delhi Branch)"
                            />

                            <FormControlLabel
                                control={<Radio />}
                                label="Advanced Option Trading course"
                                value="Advanced Option Trading course"
                            />

                            <FormControlLabel
                                control={<Radio />}
                                label="Global Market & Forex course"
                                value="Global Market & Forex course"
                            />
                        </FormGroup>
                    </FormControl>
                </RadioGroup>
            </Box>
        </>
    );
};

export const OfflineClasses = ({ offlineClass, setOfflineClass }) => {
    return (
		<>
			<Typography variant="h5" gutterBottom>
				6 -For offline course select the centre from below given list to
				schedule a meeting?
			</Typography>

			<Box sx={{ display: "flex" }}>
				<RadioGroup
					aria-labelledby="offlineClass-radio-buttons-group-label"
					defaultValue="offlineClass"
					name="radio-buttons-group"
					value={offlineClass}
					onChange={(e) => setOfflineClass(e.target.value)}
				>
					<FormControl
						sx={{ m: 3 }}
						component="fieldset"
						variant="standard"
					>
						<FormGroup>
							<FormControlLabel
								control={<Radio />}
								label="Delhi"
								value="Delhi"
							/>
							{/* <FormControlLabel
                                control={<Radio />}
                                label="Pune"
                                value="Pune"
                            /> */}

							<FormControlLabel
								control={<Radio />}
								label="Dubai"
								value="Dubai"
							/>
						</FormGroup>
					</FormControl>
				</RadioGroup>
			</Box>
		</>
	);
};

export const PhoneNumber = ({
    phone,
    setPhone,
    onChangeEvent,
    phoneValidated,
}) => {
    return (
        <>
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <Typography variant="h5" gutterBottom>
                    What's your Phone Number?
                </Typography>
                <Typography variant="body1" gutterBottom>
                    We’ll only call you at your convenience with relevant
                    information only. We never share our client details with
                    anyone else.
                </Typography>
                <Input
                    placeholder="Type your answer here..."
                    startAdornment={
                        <InputAdornment position="start">
                            <LocalPhoneIcon />
                        </InputAdornment>
                    }
                    variant="outlined"
                    required
                    id="phone"
                    name="phone"
                    autoComplete="phone"
                    autoFocus
                    value={phone}
                    onChange={(e) => onChangeEvent(e)}
                />
                {!phone ? (
                    <Typography variant="p" gutterBottom sx={{ color: "#777" }}>
                        This field is required.
                    </Typography>
                ) : phone !== 10 ? (
                    <Typography variant="p" gutterBottom sx={{ color: "#777" }}>
                        This field is required.
                    </Typography>
                ) : !phoneValidated ? (
                    <Typography variant="p" gutterBottom sx={{ color: "red" }}>
                        Error!! Inavalid phone number.
                    </Typography>
                ) : (
                    ""
                )}
            </FormControl>
        </>
    );
};
export const Location = ({ location, locationSet }) => {
    return (
        <>
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <Typography variant="h5" gutterBottom>
                    Where are you currently located?
                </Typography>
                <Input
                    placeholder="Type your answer here..."
                    startAdornment={
                        <InputAdornment position="start">
                            <LocationOn />
                        </InputAdornment>
                    }
                    variant="outlined"
                    required
                    id="location"
                    name="location"
                    autoComplete="location"
                    autoFocus
                    value={location}
                    onChange={(e) => locationSet(e.target.value)}
                />
            </FormControl>
        </>
    );
};

export const Submit = ({ submit, setSubmit }) => {
    return (
        <>
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <Typography variant="h5" gutterBottom>
                    Where are you currently located?
                </Typography>
                <Input
                    placeholder="Type your answer here..."
                    variant="outlined"
                    required
                    fullWidth
                    id="submit"
                    name="submit"
                    autoComplete="submit"
                    autoFocus
                    value={submit}
                    // onChange={(e) => setSubmit(e.target.value)}
                />
            </FormControl>
        </>
    );
};
