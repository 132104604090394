import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Grid, Button } from "@mui/material";
// import EnrollFrom from "../components/EnrollFrom";
import Typography from "@mui/material/Typography";
import UserResources from "../api/UserResources";
import Users from "../components/Users";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LeadList from "../components/LeadList";
import EastIcon from "@mui/icons-material/East";

const AppWidgetSummary = ({ title, total, bg, color, goto, isStudent }) => {
  return (
    <Card sx={{ background: bg }} elevation={0}>
      <CardContent sx={{ color: color }}>
        {!isStudent && <Box sx={{ fontSize: "2rem" }}>{total}</Box>}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>{title}</span>
          {goto && isStudent && (
            <Link to={goto}>
              <EastIcon
                style={{
                  color: "white",
                  border: "1px solid white",
                  borderRadius: "50%",
                  padding: "8px",
                  width: "30px",
                  height: "30px",
                  background: "#4a4f5252",
                }}
                // fontSize={"70px"}
              />
            </Link>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default function Dashboard() {
  const navigate = useNavigate();

  const userInfo = useSelector((state) => state.user.userInfo);

  const [stats, setStats] = useState({});
  const DashboardApi = async (params) => {
    params = {
      user_id: userInfo.id === 2 ? userInfo.id : null,
    };
    Object.keys(params).forEach((key) => {
      if (params[key] === null) {
        delete params[key];
      }
    });
    try {
      const response = await UserResources.dashBoardStats(params);
      console.log("response", response.data);
      setStats(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    DashboardApi();
  }, []);

  const statsData = stats.data || {};
  const follow_ups_count = statsData.follow_ups_count || {};
  const incentive_count = statsData.incentive_count || {};

  const isStudent = userInfo.role_type == 4;
  const isTeacher = userInfo.role_type == 3;

  return (
    <>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Hi, Welcome back
      </Typography>
      <Button
        onClick={() => navigate("/sales")}
        sx={{ mt: 1, mr: 1, mb: 5 }}
        variant="contained"
      >
        All Sales
      </Button>
      <Grid container spacing={3} sx={{ mb: 5 }}>
        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
            title={isStudent ? "My Classes" : "Total Leads"}
            total={statsData.total_leads}
            bg={"#2196F3"}
            color={"#fff"}
            isStudent={isStudent}
            // isTeacher={isTeacher}
            goto={"/my-classes"}
            icon={"ant-design:android-filled"}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
            title={isStudent ? "Attendance" : "Potential Leads"}
            total={follow_ups_count.potential_lead}
            bg="#83a858"
            color={"#fff"}
            icon={"ant-design:apple-filled"}
            isStudent={isStudent}
            // isTeacher={isTeacher}
            goto={"/attendance"}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
            title={isStudent ? "Payment History" : "Dead Leads"}
            total={follow_ups_count.dead_lead}
            bg="#e91e63"
            color={"#fff"}
            icon={"ant-design:windows-filled"}
            isStudent={isStudent}
            // isTeacher={isTeacher}
            goto={"/payment-history"}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
            title={isStudent ? "Profile" : "Converted Lead"}
            total={follow_ups_count.converted_lead}
            bg="#4CAF50"
            color={"#fff"}
            icon={"ant-design:bug-filled"}
            isStudent={isStudent}
            // isTeacher={isTeacher}
            goto={"/profile"}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
            title={isStudent ? "" : "Total Incentive Amount"}
            total={incentive_count.incentive_incentive_amount}
            bg="red"
            color={"#fff"}
            icon={"ant-design:windows-filled"}
            isStudent={isStudent}
            // isTeacher={isTeacher}
            // goto={"/payment-history"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary
            title={isStudent ? "" : "Total Sale Amount"}
            total={incentive_count.incentive_total_amount}
            bg="#4CAF50"
            color={"#fff"}
            icon={"ant-design:windows-filled"}
            isStudent={isStudent}
            // isTeacher={isTeacher}
            // goto={"/payment-history"}
          />
        </Grid>

        {userInfo.role_type == 1 && (
          <Grid item sm={12} md={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h5" fontWeight={"bold"}>
                Recent Users
              </Typography>
            </Box>
            <Users isDashboard={true} />
          </Grid>
        )}
      </Grid>
    </>
  );
}
