import React, { useEffect, useState } from "react";
import { Chip, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import UserResources from "../api/UserResources";
import useLoadingBackdrop from "../hooks/useLoadingBackdrop";
// import { Pagination, Select, MenuItem } from "@mui/material";
import dateFormat from "dateformat";
import { TablePagination } from "@mui/material";
import CustomTablePaginator from "./CustomTablePaginator";

export default function ListInTableView({ PageLabel, userType }) {
    const { LoadingBackdrop } = useLoadingBackdrop();
    const [loading, setLoading] = useState(false);
    const [data, dataSet] = useState([]);
    const [paginatedData, paginatedDataSet] = useState([]);
    const [currentPage, currentPageSet] = useState(0);
    const [perPageItem, perPageItemSet] = useState(10);

    // Fetch all batches
    const Batches = async (params) => {
        var params = {
                page: currentPage,
                limit: perPageItem,
            },
            params;

        setLoading(true);
        try {
            const res = await UserResources.getBatchList(params);

            console.log("batch data", res.data.data);
            if (res.status) {
                dataSet(res.data.data);
                paginatedDataSet(res.data.paginate_data);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        Batches();
    }, [currentPage, perPageItem]);

    // function handleChangePage(event, newpage) {
    //     perPageItemSet(newpage);
    // }

    // function handleChangeRowsPerPage(event) {
    //     perPageItemSet(parseInt(event.target.value));
    //     currentPageSet(0);
    // }

    const handlePageChange = (page) => {
        currentPageSet(page);
    };

    const handlePerPageChange = (event) => {
        perPageItemSet(event.target.value);
        currentPageSet(1);
    };

    return (
        <>
            <Typography variant="h4" gutterBottom>
                {PageLabel}
            </Typography>

            {loading && <LoadingBackdrop />}

            <TableContainer component={Paper} px={30}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>S. No.</TableCell>

                            {<TableCell>Batch</TableCell>}
                            {<TableCell>Start Date</TableCell>}
                            {<TableCell>End Date</TableCell>}
                            {<TableCell>Start Time</TableCell>}
                            {<TableCell>End Time</TableCell>}
                            {<TableCell>Status</TableCell>}
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {data.map((item, index) => (
                            <TableRow key={item.id}>
                                <TableCell component="th" scope="row">
                                    {index + 1}
                                </TableCell>

                                <TableCell>{item.name}</TableCell>
                                <TableCell>
                                    {dateFormat(
                                        item.start_date,
                                        "mmm dd, yyyy"
                                    )}
                                </TableCell>
                                <TableCell>
                                    {dateFormat(item.end_date, "mmm dd, yyyy")}
                                </TableCell>
                                <TableCell>{item.start_time}</TableCell>
                                <TableCell>{item.end_time}</TableCell>
                                {
                                    <TableCell>
                                        <Chip
                                            label={
                                                item.status == 1
                                                    ? "Active"
                                                    : "NA"
                                            }
                                            color="primary"
                                            variant="outlined"
                                            size="small"
                                        />
                                    </TableCell>
                                }

                                <TableCell>
                                    <Link
                                        to={`/batch/${item.id}`}
                                        state={{ data: item }}
                                    >
                                        <Button
                                            variant="contained"
                                            disableElevation
                                            size="small"
                                        >
                                            View
                                        </Button>
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* <TablePagination
                rowsPerPageOptions={[10, 30, 60, 90, 120, 150]}
                component="div"
                count={paginatedData.total}
                rowsPerPage={perPageItem}
                page={currentPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
            <CustomTablePaginator
                count={paginatedData.total}
                rowsPerPage={perPageItem}
                page={currentPage}
                onPageChange={(newPage) => {
                    currentPageSet(newPage);
                }}
                onChangeRowsPerPage={(newRowsPerPage) => {
                    perPageItemSet(parseInt(newRowsPerPage));
                    currentPageSet(0);
                }}
            />

            {/* {!loading && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "right",
                        my: 3,
                        alignItems: "center",
                    }}
                >
                    {console.log(perPageItem)}
                    <Select
                        value={perPageItem}
                        onChange={handlePerPageChange}
                        size="small"
                    >
                        <MenuItem value={5}>5 per page</MenuItem>
                        <MenuItem value={10}>10 per page</MenuItem>
                        <MenuItem value={15}>15 per page</MenuItem>
                        <MenuItem value={150}>150 per page</MenuItem>
                    </Select>

                    <Pagination
                        variant="outlined"
                        color="primary"
                        count={paginatedData.last_page}
                        page={currentPage}
                        onChange={(event, page) => handlePageChange(page)}
                    />
                </Box>
            )} */}
        </>
    );
}
