import React, { useEffect, useState } from "react";
import { IconButton, Slide, Snackbar, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import CloseIcon from "@mui/icons-material/Close";
// import LoadingButton from "@mui/lab/LoadingButton";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import UserResources from "../api/UserResources";
import useLoadingBackdrop from "../hooks/useLoadingBackdrop";
// import { Pagination, Select, MenuItem } from "@mui/material";
import dateFormat from "dateformat";
import { TablePagination } from "@mui/material";
import { UseSelector, useSelector } from "react-redux/es/hooks/useSelector";
import CustomTablePaginator from "./CustomTablePaginator";
function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}
const EnrollmentList = ({ PageLabel, userType, rows }) => {
    const { LoadingBackdrop } = useLoadingBackdrop();
    const [loading, setLoading] = useState(false);
    const [data, dataSet] = useState([]);
    const [paginatedData, paginatedDataSet] = useState([]);
    const [currentPage, currentPageSet] = useState(0);
    const [perPageItem, perPageItemSet] = useState(10);
    const userInfo = useSelector((state) => state.user);

    const [open, setOpen] = useState(false);
    const [message, messageSet] = useState("");

    const Enrollment = async (event) => {
        setLoading(true);
        const params = {
            limit: perPageItem,
            page: currentPage,
            // user_id: userInfo?.id === 2 ? "" : userInfo?.id,
        };
        try {
            const res = await UserResources.enrollmentList(params);
            // console.log("response", res.data.data);

            if (res.status) {
                dataSet(res.data.data);
                paginatedDataSet(res.data.paginate_data);
            }
        } catch (error) {
            setOpen(true);

            console.log(error);
            if (error.response && error.response.status == 422) {
                if (error.response.data.errors.limit) {
                    messageSet(error.response.data.errors.limit[0]);
                }
            }
        }
        setLoading(false);
    };

    // function handleChangePage(event, newpage) {
    //     perPageItemSet(newpage);
    // }

    // function handleChangeRowsPerPage(event) {
    //     perPageItemSet(parseInt(event.target.value));
    //     currentPageSet(0);
    // }

    // const handlePageChange = (page) => {
    //     currentPageSet(page);
    // };

    // const handlePerPageChange = (event) => {
    //     perPageItemSet(event.target.value);
    //     currentPageSet(1);
    // };

    useEffect(() => {
        Enrollment();
    }, [currentPage, perPageItem]);
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="h4" gutterBottom>
                    {PageLabel} ({data.length})
                </Typography>
                <Button
                    disableElevation
                    size="small"
                    // loading={loading}
                    variant="outlined"
                    onClick={(e) => {
                        Enrollment(e);
                    }}
                >
                    Refresh
                </Button>
            </Box>

            {loading && <LoadingBackdrop />}

            <TableContainer component={Paper} px={30}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>S. No.</TableCell>

                            {<TableCell>Name</TableCell>}
                            {<TableCell>Course</TableCell>}
                            {<TableCell>Contact</TableCell>}
                            {<TableCell>Email</TableCell>}
                            {/* <TableCell>Payment Status</TableCell> */}
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {data.map((item, index) => (
                            <TableRow key={item?.id}>
                                <TableCell component="th" scope="row">
                                    {index + 1}
                                </TableCell>

                                <TableCell>{item?.user.name}</TableCell>
                                <TableCell>{item?.course.name}</TableCell>
                                <TableCell>{item?.phone}</TableCell>
                                <TableCell>{item?.email}</TableCell>
                                {/* <TableCell>
									{item?.payment_type == "partial_payment" ? (
										<Chip
											label="Partial"
											color="primary"
											size="small"
											sx={{
												height: "auto",
												"& .MuiChip-label": {
													fontSize: 12,
												},
											}}
										/>
									) : (
										<Chip
											label="Fully Paid"
											color="success"
											size="small"
											sx={{
												height: "auto",
												"& .MuiChip-label": {
													fontSize: 12,
												},
											}}
										/>
									)}
								</TableCell> */}

                                <TableCell>
                                    {/* {JSON.stringify(item.payments)} */}

                                    <a
                                        href={`https://api.tradinggamestrong.com/payment-gateway?enrollment_id=${item.id}`}
                                        target="blank"
                                    >
                                        <Button
                                            variant="contained"
                                            disableElevation
                                            size="small"
                                            sx={{ mr: 1 }}
                                        >
                                            payment
                                        </Button>
                                    </a>

                                    <Link
                                        to={`/all-enrollment/${item.id}`}
                                        state={{
                                            data: item,
                                        }}
                                        title="Payment Details"
                                    >
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            size="small"
                                        >
                                            Details
                                        </Button>
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* <TablePagination
                rowsPerPageOptions={[10, 30, 60, 90, 120, 150]}
                component="div"
                count={paginatedData.total}
                rowsPerPage={perPageItem}
                page={currentPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
            <CustomTablePaginator
                count={paginatedData.total}
                rowsPerPage={perPageItem}
                page={currentPage}
                onPageChange={(newPage) => {
                    currentPageSet(newPage);
                }}
                onChangeRowsPerPage={(newRowsPerPage) => {
                    perPageItemSet(parseInt(newRowsPerPage));
                    currentPageSet(0);
                }}
            />

            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={(e) => setOpen(false)}
                message={message}
                TransitionComponent={TransitionUp}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={(e) => setOpen(false)}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
        </>
    );
};

export default EnrollmentList;
