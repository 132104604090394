import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useEffect, useRef } from "react";
import CssBaseline from "@mui/material/CssBaseline";
// import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
// import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { useState } from "react";
import UserResources from "../api/UserResources";
import dateFormat from "dateformat";
import Slide from "@mui/material/Slide";
import { useNavigate, useLocation } from "react-router-dom";
import { FormLabel, useForkRef } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}
const defaultTheme = createTheme();

const SalesForm = () => {
  const formRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();

  const isUpdate = location?.state?.isUpdate;
  const [loading, loadingSet] = useState(false);
  const [open, setOpen] = useState(false);

  const [gst, setGst] = useState(
    location?.state?.val ? location.state.val.gst_percentage : ""
  );
  const [userPercentage, setUserPercentage] = useState(
    location?.state?.val ? location.state.val.incentive_percentage : ""
  );
  const [remaining, setRemaining] = useState("");

  const [amount, setAmount] = useState(
    location?.state?.val ? location.state.val.total_amount : ""
  );
  const [incentiveAmount, setIncentiveAmount] = useState(
    location?.state?.val ? location.state.val.incentive_amount : ""
  );
  const [message, messageSet] = useState("");
  const [startDate, setStartDate] = useState(
    location?.state?.val
      ? location.state.val.date
      : dateFormat(new Date(), "yyyy-MM-dd")
  );
  const [endDate, setEndDate] = useState(
    location?.state?.val
      ? location.state.val.end_date
      : dateFormat(new Date(), "yyyy-MM-dd")
  );
  const [select, setSelect] = useState(
    location?.state?.val ? location.state.val.user_id : ""
  );
  const [counsellorList, setCounsellorList] = useState([]);

  const UserList = async () => {
    var params = {
      role_type: 2,
    };

    try {
      const res = await UserResources.user_list(params);

      if (res.data.status) {
        setCounsellorList(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const signUp = async (e) => {
    e.preventDefault();
    loadingSet(true);

    try {
      const res = await UserResources.sales(
        isUpdate
          ? {
              id: location.state.val.id,
              user_id: select,
              total_amount: amount,
              date: startDate,
              gst_percentage: gst,
              incentive_percentage: userPercentage,
              incentive_amount: incentiveAmount,
            }
          : {
              user_id: select,
              total_amount: amount,
              date: startDate,
              gst_percentage: gst,
              incentive_percentage: userPercentage,
              incentive_amount: incentiveAmount,
            }
      );
      console.log("register response", res);
      if (res.status) {
        navigate("/all-counsellors");
      }
    } catch (error) {
      setOpen(true);
      messageSet("Faild");
      loadingSet(false);
    }
  };

  const CalulationGST = () => {
    var remaningAmount = (parseFloat(amount) * parseFloat(gst)) / 100;
    remaningAmount = parseFloat(amount) - remaningAmount;
    setRemaining(remaningAmount);
    console.log("reaming", remaningAmount);
  };

  const CalculateInsntive = () => {
    var remaningAmount =
      (parseFloat(remaining) * parseFloat(userPercentage)) / 100;
    remaningAmount = remaningAmount.toFixed(2);

    setIncentiveAmount(remaningAmount);
  };

  useEffect(() => {
    UserList();
  }, []);

  useEffect(() => {
    CalulationGST();
  }, [amount, gst, userPercentage]);

  useEffect(() => {
    CalculateInsntive();
  }, [userPercentage, incentiveAmount, amount, gst]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={(e) => setOpen(false)}
        message={message}
        TransitionComponent={TransitionUp}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={(e) => setOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />

      <Grid container>
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          sx={{ mx: "auto", py: 3 }}
          square
        >
          <Box
            sx={{
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Sales
            </Typography>

            <Box
              component="form"
              noValidate
              onSubmit={signUp}
              sx={{ mt: 1 }}
              ref={formRef}
            >
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={select}
                onChange={(e) => setSelect(e.target.value)}
                fullWidth
              >
                {counsellorList.map((value, index) => (
                  <MenuItem value={value.id}>{value.name}</MenuItem>
                ))}
              </Select>

              <FormLabel>Date</FormLabel>
              <TextField
                sx={{ mt: 0 }}
                variant="outlined"
                margin="normal"
                required
                type="date"
                fullWidth
                id="startDate"
                name="startDate"
                autoComplete="startDate"
                autoFocus
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />

              {/* <FormLabel>End Date</FormLabel>

              <TextField
                sx={{ mt: 0 }}
                variant="outlined"
                margin="normal"
                required
                type="date"
                fullWidth
                id="endDate"
                name="endDate"
                autoComplete="endDate"
                autoFocus
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              /> */}
              <TextField
                sx={{
                  marginBottom: "10px",
                }}
                id="outlined-amount-input"
                label="Sale Amount"
                type="number"
                autoComplete="current-amount"
                fullWidth
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
              />

              <TextField
                sx={{
                  marginBottom: "10px",
                }}
                id="outlined-incentive-amount-input"
                label="GST %"
                type="number"
                autoComplete="incentive_amount"
                fullWidth
                onChange={(e) => setGst(e.target.value)}
                value={gst}
              />
              <TextField
                sx={{
                  marginBottom: "10px",
                }}
                id="outlined-incentive-amount-input"
                label="Counsellor %"
                type="number"
                autoComplete="incentive_amount"
                fullWidth
                onChange={(e) => setUserPercentage(e.target.value)}
                value={userPercentage}
              />
              <TextField
                sx={{
                  marginBottom: "10px",
                }}
                id="outlined-incentive-amount-input"
                label="Incentive Amount"
                type="number"
                disabled={true}
                autoComplete="incentive_amount"
                fullWidth
                //onChange={(e) => setIncentiveAmount(e.target.value)}
                value={incentiveAmount}
              />
              <Button
                type="submit"
                fullWidth
                disabled={loading}
                variant="contained"
                sx={{ mt: 3 }}
                disableElevation
              >
                {loading ? "Please wait.." : "Submit"}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default SalesForm;
