import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { useRef } from "react";
import { Button, Box, Typography, Card, CardContent, IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import UserResources from "../api/UserResources";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
// import LoadingButton from "@mui/lab/LoadingButton";

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

export default function Password({ isRestPassword }) {
    const formRef = useRef();
    const [open, setOpen] = useState(false);
    const [loading, loadingSet] = useState(false);
    const [message, messageSet] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");

    const [confirmPassword, confirmPasswordSet] = useState("");

    const updatePassword = async (e) => {
        loadingSet(true);
        e.preventDefault();

        try {
            if (isRestPassword) {
                const res = await UserResources.change_password({
                    email,
                });
                if (res.status) {
                    setOpen(true);
                    messageSet(res.data.message);
                    formRef.current.reset();
                }
            } else {
                const res = await UserResources.update_user_password({
                    password: password,
                    password_confirmation: confirmPassword,
                });
                if (res.status) {
                    setOpen(true);
                    messageSet(res.data.message);
                    formRef.current.reset();
                }
            }
        } catch (error) {
            setOpen(true);
            messageSet(error.response.data.message);
        }
        loadingSet(false);
    };

    return (
        <Box sx={isRestPassword ? { mt: 5 } : ""}>
            <Typography variant="h4" gutterBottom style={{ textAlign: "center" }}>
                {isRestPassword ? "Reset" : "Update"} Password
            </Typography>
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={(e) => setOpen(false)}
                message={message}
                TransitionComponent={TransitionUp}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={(e) => setOpen(false)}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />

            <Grid container spacing={3} maxWidth={"sm"} margin={"auto"}>
                <Grid item xs={12} sm={12}>
                    <Box component="form" onSubmit={updatePassword} ref={formRef}>
                        <Card>
                            {isRestPassword ? (
                                <CardContent>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="email"
                                        label="Email"
                                        type="email"
                                        id="email"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <Button
                                        disableElevation
                                        // loading={loading}
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                    >
                                        {loading ? "Please wait.." : "Update Password"}
                                    </Button>
                                </CardContent>
                            ) : (
                                <CardContent>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />

                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password_confirmation"
                                        label="Confirm Password"
                                        type="password"
                                        id="password"
                                        onChange={(e) => confirmPasswordSet(e.target.value)}
                                    />
                                    <Button
                                        disableElevation
                                        // loading={loading}
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                    >
                                        {loading ? "Please wait.." : "Update Password"}
                                    </Button>
                                </CardContent>
                            )}
                        </Card>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
