import React from "react";
import UserResources from "../api/UserResources";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { Box, Grid, Card, CardContent } from "@mui/material";
import { styled } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Gingerbread", 356, 16.0, 49, 3.9),
];
const PaymentHistory = () => {
    const userInfo = useSelector((state) => state.user.userInfo);
    const [expanded, setExpanded] = React.useState("panel1");
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const [data, setData] = React.useState([]);

    const fetchData = async () => {
        try {
            const response = await UserResources.enrollmentList({ user_id: userInfo.id });
            console.log("response", response.data.data);
            setData(response.data.data);
        } catch (error) {
            console.log(error);
        }
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {/* <TableCell>Course</TableCell> */}
                        <TableCell>amount</TableCell>
                        <TableCell>method</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item, index) => {
                        return (
                            item.payments &&
                            item.payments.map((item) => (
                                <TableRow
                                    key={item?.id}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <TableCell>{item?.amount}</TableCell>
                                    <TableCell>{item?.method}</TableCell>
                                </TableRow>
                            ))
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PaymentHistory;
