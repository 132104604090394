import React, { useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { CardContent, Card, Box } from "@mui/material";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import { useSelector } from "react-redux";
import UserResources from "../api/UserResources";
import { useNavigate } from "react-router-dom";
import {
    Amazing,
    Classes,
    Earning,
    Email,
    Experience,
    Location,
    Name,
    OfflineClasses,
    OnlineOrRecordedClasses,
    PhoneNumber,
    Profession,
} from "../components/LeadFields";

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

const experienceOptions = [
    { id: "3", label: "No experience" },
    { id: "2", label: "Experienced Small Losses" },
    { id: "4", label: "Experienced but huge Losses" },
    { id: "1", label: "Experienced with Profit/Break-even" },
];

const LeadForm = ({isPublic}) => {
    const formRef = useRef();
    const isLogin = useSelector((state) => state.user.loggedIn);
    const [loading, loadingSet] = React.useState(false);
    const navigate = useNavigate();
    const [openSnackbar, openSnackbarSet] = React.useState(false);
    const [phoneValidated, phoneValidatedSet] = React.useState(false);
    const [message, messageSet] = React.useState("");
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [profession, setProfession] = React.useState("");
    const [earning, setEarning] = React.useState("");
    const [experience, setExperience] = React.useState(
        experienceOptions[0].label
    );
    const [experienceID, setExperienceID] = React.useState(
        experienceOptions[0].id
    );
    const [offering, setOffering] = React.useState("");
    const [classesType, setClassesType] = React.useState("");
    const [onlineRecordedClasses, setOnlineRecordedClasses] =
        React.useState("");
    const [offlineClass, setOfflineClass] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [location, locationSet] = React.useState("");

    const index = [{ step: 1, value: name }];

    function validatePhone(inputtxt) {
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (inputtxt && inputtxt.match(phoneno)) {
            phoneValidatedSet(true);
            return true;
        } else {
            console.log("error");
            phoneValidatedSet(false);
            return false;
        }
    }

    const saveLead = async (params) => {
        var params = {
            email: email,
            phone: phone,
            name: name,
            details: {
                profession: profession,
                earning: earning,
                experience: experience,
                experience_id: experienceID,
                offering: offering,
                classesType: classesType,
                onlineRecordedClasses: onlineRecordedClasses,
                offlineClass: offlineClass,
                location: location,
            },
        };
        loadingSet(true);
        try {
            let res;
            if(isPublic ) {
                res = await UserResources.saveUpdateLeadPublic(params);
            } else {
                res = await UserResources.saveUpdateLead(params);
            }
            console.log(res)
            if (res.status) {
                openSnackbarSet(true);
                alert('Success')
                navigate('/form-status')
                // messageSet('Your details have been saved successfully! We will get back to you shortly!');
                messageSet(res.data.message);
                // formRef.current.reset();
            }
        } catch (error) {
            openSnackbarSet(true);
            console.log(error);
            // messageSet('Something went wrong!');
            // messageSet(error.response.data.message);
            if (error.response && error.response.status == 422) {
				if (error.response.data.errors.email) {
					alert(error.response.data.errors.email[0]);
				}
			}
        }
        loadingSet(false);
    };
    const [step, setStep] = React.useState(1);

    const handleSubmit = () => {
        saveLead();
        console.clear();
        // console.log(formattedData);
    };

    const handleBack = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };

    const handleNext = () => {
        setStep(step + 1);
    };

    const Wrapper = ({ children }) => {
        return (
            <Card sx={{ maxWidth: "578px", mx: "auto", mt: 5 }}>
                <CardContent>{children}</CardContent>
            </Card>
        );
    };

    switch (step) {
        case 1:
            return (
                <Wrapper>
                    <Name name={name} setName={setName} />
                    <Button
                        disabled={step === 1}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                        disableElevation
                    >
                        Back
                    </Button>
                    <Button
                        disabled={!name}
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                    >
                        Next Step
                    </Button>
                </Wrapper>
            );

        case 2:
            return (
                <Wrapper>
                    <Profession
                        profession={profession}
                        setProfession={setProfession}
                    />
                    <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                        disableElevation
                    >
                        Back
                    </Button>
                    <Button
                        disabled={!profession}
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                    >
                        Next Step
                    </Button>
                </Wrapper>
            );
        case 3:
            return (
                <Wrapper>
                    <Earning earning={earning} setEarning={setEarning} />
                    <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                        disableElevation
                    >
                        Back
                    </Button>
                    <Button
                        disabled={!earning}
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                    >
                        Next Step
                    </Button>
                </Wrapper>
            );
        case 4:
            return (
                <Wrapper>
                    <Experience
                        experience={experience}
                        setExperience={setExperience}
                        experienceOptions={experienceOptions}
                        experienceID={experienceID}
                        setExperienceID={setExperienceID}
                    />
                    <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                        disableElevation
                    >
                        Back
                    </Button>
                    <Button
                        disabled={!experience}
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                    >
                        Next Step
                    </Button>
                </Wrapper>
            );
        case 5:
            return (
                <Wrapper>
                    {experienceID == 1 ? (
                        <>
                            <Amazing
                                offering={offering}
                                setOffering={setOffering}
                            />
                            <Button
                                disabled={index === 0}
                                onClick={handleBack}
                                sx={{ mt: 1, mr: 1 }}
                                disableElevation
                            >
                                Back
                            </Button>
                            <Button
                                disabled={!offering}
                                variant="contained"
                                onClick={() => setStep(step + 2)}
                                sx={{ mt: 1, mr: 1 }}
                            >
                                Next Step
                            </Button>
                        </>
                    ) : (
                        <>
                            <Classes
                                classesType={classesType}
                                setClassesType={setClassesType}
                            />
                            <Button
                                disabled={index === 0}
                                onClick={handleBack}
                                sx={{ mt: 1, mr: 1 }}
                                disableElevation
                            >
                                Back
                            </Button>
                            <Button
                                disabled={!classesType}
                                variant="contained"
                                onClick={handleNext}
                                sx={{ mt: 1, mr: 1 }}
                            >
                                Next Step
                            </Button>
                        </>
                    )}
                </Wrapper>
            );
        case 6:
            return (
                <Wrapper>
                    {console.log("classType", classesType)}
                    {classesType == "offline" ? (
                        <>
                            <OfflineClasses
                                offlineClass={offlineClass}
                                setOfflineClass={setOfflineClass}
                            />
                            <Button
                                disabled={index === 0}
                                onClick={handleBack}
                                sx={{ mt: 1, mr: 1 }}
                                disableElevation
                            >
                                Back
                            </Button>
                            <Button
                                disabled={!OfflineClasses}
                                variant="contained"
                                onClick={handleNext}
                                sx={{ mt: 1, mr: 1 }}
                            >
                                Next Step
                            </Button>
                        </>
                    ) : (
                        <>
                            <OnlineOrRecordedClasses
                                onlineRecordedClasses={onlineRecordedClasses}
                                setOnlineRecordedClasses={
                                    setOnlineRecordedClasses
                                }
                            />
                            <Button
                                disabled={index === 0}
                                onClick={handleBack}
                                sx={{ mt: 1, mr: 1 }}
                                disableElevation
                            >
                                Back
                            </Button>
                            <Button
                                disabled={!onlineRecordedClasses}
                                variant="contained"
                                onClick={handleNext}
                                sx={{ mt: 1, mr: 1 }}
                            >
                                Next Step
                            </Button>
                        </>
                    )}
                </Wrapper>
            );
        case 7:
            return (
                <Wrapper>
                    <Email email={email} setEmail={setEmail} />
                    <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                        disableElevation
                    >
                        Back
                    </Button>
                    <Button
                        disabled={!email}
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                    >
                        Next Step
                    </Button>
                </Wrapper>
            );
        case 8:
            return (
                <Wrapper>
                    <PhoneNumber
                        phone={phone}
                        setPhone={setPhone}
                        onChangeEvent={(e) => {
                            setPhone(e.target.value);
                            validatePhone(e.target.value);
                        }}
                        phoneValidated={phoneValidated}
                    />
                    <Button
                        disabled={step === 1}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                        disableElevation
                    >
                        Back
                    </Button>
                    <Button
                        disabled={phoneValidated != true}
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                    >
                        Next Step
                    </Button>
                </Wrapper>
            );
        case 9:
            return (
                <Wrapper>
                    <Location location={location} locationSet={locationSet} />
                    <Button
                        disabled={step === 1}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                        disableElevation
                    >
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        sx={{ mt: 1, mr: 1 }}
                    >
                        Submit
                    </Button>
                </Wrapper>
            );
    }
};

export default LeadForm;
