import React from "react";
import { Box, Grid, Typography, Card, CardContent, Chip } from "@mui/material";
import { useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { Edit } from "@mui/icons-material";
import FollowUpModal from "./FollowUpModal";
import dateFormat from "dateformat";

export default function BatchSingle({ isBatchView }) {
    const [open, setOpen] = React.useState(false);
    const [status, statusSet] = React.useState("");
    const location = useLocation();
    const { data } = location.state || {};
    console.log(data);
    const handleChange = (event) => {
        statusSet(event.target.value);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Grid container spacing={3} maxWidth={"sm"}>
                <Grid item sm={12} xs={12}>
                    <Card elevation={0}>
                        <CardContent>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    borderBottom: "1px solid #ddd",
                                    py: 2,
                                }}
                            >
                                <Typography
                                    color="text.secondary"
                                    fontSize={12}
                                >
                                    {!isBatchView ? "Lead ID" : "ID"}
                                </Typography>
                                <Typography>
                                    {/* {!isBatchView ? null : data.id} */}
                                    {isBatchView ? data.id : null}
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    borderBottom: "1px solid #ddd",
                                    py: 2,
                                }}
                            >
                                <Typography
                                    color="text.secondary"
                                    fontSize={12}
                                >
                                    {!isBatchView ? "Lead Type" : "Status"}
                                </Typography>
                                <Box>
                                    <Chip
                                        label={
                                            !isBatchView
                                                ? `Potential Lead`
                                                : `Active`
                                        }
                                        color="primary"
                                        variant="outlined"
                                        size="small"
                                        sx={
                                            !isBatchView ? { mr: 1 } : { mr: 0 }
                                        }
                                    />
                                    {!isBatchView && (
                                        <IconButton
                                            edge="end"
                                            aria-label="edit"
                                            onClick={handleClickOpen}
                                            variant="contained"
                                            size="small"
                                            sx={{
                                                bgcolor: "#3f51b5",
                                                color: "#fff",
                                                "&:hover": {
                                                    backgroundColor:
                                                        "primary.main",
                                                    opacity: [0.9, 0.8, 0.7],
                                                },
                                            }}
                                        >
                                            <Edit fontSize="20px" />
                                        </IconButton>
                                    )}
                                </Box>
                            </Box>
                            {isBatchView && (
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        borderBottom: "1px solid #ddd",
                                        py: 2,
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            color="text.secondary"
                                            fontSize={12}
                                        >
                                            Course
                                        </Typography>

                                        <Typography>{data.name}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            color="text.secondary"
                                            fontSize={12}
                                        >
                                            Start Date
                                        </Typography>
                                        <Typography>
                                            {" "}
                                            {dateFormat(
                                                data.start_date,
                                                "mmm dd, yyyy"
                                            )}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            color="text.secondary"
                                            fontSize={12}
                                        >
                                            End Date
                                        </Typography>
                                        <Typography>
                                            {" "}
                                            {dateFormat(
                                                data.end_date,
                                                "mmm dd, yyyy"
                                            )}
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <FollowUpModal
                handleClose={handleClose}
                open={open}
                handleChange={handleChange}
            />
        </>
    );
}
