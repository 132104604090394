import config from "../config";
import axios from "./base";

const api_url = config.api_url;

const UserResources = {
    register: (params) => {
        return axios.post(api_url + "/register", params);
    },
    login: (params) => {
        return axios.post(api_url + "/login", params);
    },
    change_password: (params) => {
        return axios.post(api_url + "/forgot-password", params);
    },

    logout: (params) => {
        return axios.get(api_url + "/user/logout", params);
    },

    user_list: (params) => {
        if (!params) {
            params = {};
        }
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        return axios.get(`${api_url}/user/user-list?${queryString}`, params);
    },

    update_user_detail: (params) => {
        return axios.post(api_url + "/user/update-user-details", params);
    },
    update_user_password: (params) => {
        return axios.post(api_url + "/user/update-password", params);
    },
    saveUpdateBatch: (params) => {
        return axios.post(api_url + "/batches/save-and-update", params);
    },
    getBatchList: (params) => {
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        return axios.get(
            `${api_url}/batches/batches-list?${queryString}`,
            params
        );
    },
    getCourseList: (params) => {
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        return axios.get(
            `${api_url}/courses/courses-list?${queryString}`,
            params
        );
    },

    saveUpdateCourse: (params) => {
        return axios.post(api_url + "/courses/save-and-update", params);
    },

    getUserInfo: (params) => {
        return axios.get(api_url + "/user/get-user-info", params);
    },

    get_classroom_list: (params) => {
        if (!params) {
            params = {};
        }
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        return axios.get(
            `${api_url}/classroom/classroom-list?${queryString}`,
            params
        );
    },

    saveUpdateClassroom: (params) => {
        return axios.post(api_url + "/classroom/save-and-update", params);
    },

    saveUpdateLead: (params) => {
        return axios.post(api_url + "/lead/save-and-update", params);
    },
    saveUpdateLeadPublic: (params) => {
        return axios.post(api_url + "/public/lead/save-and-update", params);
    },
    assignLead: (params) => {
        return axios.post(api_url + "/lead/update-lead", params);
    },
    getLeadList: (params) => {
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        return axios.get(`${api_url}/lead/leads-list?${queryString}`, params);
    },

    followupList: (params) => {
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        return axios.get(
            `${api_url}/follow-up/follow-up-list?${queryString}`,
            params
        );
    },

    saveUpdateFollowUp: (params) => {
        return axios.post(api_url + "/follow-up/save-and-update", params);
    },

    uploadFile: (params) => {
        return axios.post(`${api_url}/csv-to-database`, params, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },
    paymentList: ({ params }) => {
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        return axios.get(`${api_url}/payments/all-payments?${queryString}`);
    },
    makePayment: () => {
        return axios.get(
            "https://trading-game-backend.jycyberhub.com/payment-gateway?course_id=1&amount=1000&user_id=1"
        );
    },
    dialerLogin: (params) => {
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        return axios.get(`${api_url}/caller/login?${queryString}`);
    },
    LPanel: (params) => {
        if (!params) {
            params = {};
        }
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        return axios.get(`${api_url}/caller/l-pannel?${queryString}`);
    },
    callLogs: (params) => {
        if (!params) {
            params = {};
        }
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        return axios.get(`${api_url}/call-log/call-log-list?${queryString}`);
    },
    dialerLogout: (params) => {
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        console.log("dialer logout", queryString);
        return axios.get(axios.get(`${api_url}/caller/logout?${queryString}`));
    },
    dialerBreak: (params) => {
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        // console.log("dialerBreak", queryString);
        return axios.get(`${api_url}/caller/pause?${queryString}`);
    },
    dialerResume: (params) => {
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        // console.log("dialerResume", queryString);
        return axios.get(`${api_url}/caller/resume?${queryString}`);
    },
    dialerCall: (params) => {
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        return axios.get(`${api_url}/caller/place-call?${queryString}`);
    },
    enrollmentSaveAndUpdate: (data) => {
        return axios.post(`${api_url}/enrollment/save-and-update`, data);
    },
    markAttandence: (data) => {
        return axios.post(`${api_url}/attendance/mark-attendance`, data);
    },
    allAttandance: (params) => {
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        return axios.get(`${api_url}/attendance/all-attendance?${queryString}`);
    },
    enrollmentList: (params) => {
        if (!params) {
            params = {};
        }
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        return axios.get(
            `${api_url}/enrollment/enrollment-list?${queryString}`
        );
    },
    dashBoardStats: (params) => {
        return axios.get(`${api_url}/other/home-page-counts`, params);
    },
    callHistory: (params) => {
        if (!params) {
            params = {};
        }
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        return axios.get(`${api_url}/caller/break-history?${queryString}`);
    },
    sales: (params) => {
        return axios.post(api_url + "/incentive/store-and-update", params);
    },
    allSales: (params) => {
        var queryString = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&");
        return axios.get(`${api_url}/incentive/list?${queryString}`);
    },
};

export default UserResources;
