import React from "react";
import {
  AppBar,
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  IconButton,
  List,
  Toolbar,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect } from "react";
import { TablePagination } from "@mui/material";
import UserResources from "../api/UserResources";
import { useState } from "react";
import dateFormat from "dateformat";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import CreateClassroom from "./CreateClassroom";
import CustomTablePaginator from "./CustomTablePaginator";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Classroom({ PageLabel }) {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [editClassroom, editClassroomSet] = useState(false);
  const [students, setStudents] = useState();
  const [editingClass, setEditingClass] = React.useState(false);
  const [classroomData, setClassroomData] = React.useState({});
  const [course, setCourse] = useState();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [perpage, setPerPage] = useState(10);
  const [paginationData, paginationDataSet] = useState([]);
  console.log("====================================");
  console.log(perpage);
  console.log("====================================");
  function handleChangePage(event, newpage) {
    setPage(newpage);
  }
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenEditClass = () => {
    editClassroomSet(true);
  };
  const handleCloseEditClass = () => {
    editClassroomSet(false);
  };

  function handleChangeRowsPerPage(event) {
    setPerPage(parseInt(event.target.value));
    setPage(0);
  }
  const ClassroomList = async (params) => {
    setLoading(true);
    var params = Object.assign(
      {
        limit: perpage,
        page: page,
      },
      params
    );
    try {
      const res = await UserResources.get_classroom_list(params);
      // console.log("res-->", res);
      // console.log("datares-->", res.data);

      if (res.status) {
        setData(res.data.data);
        paginationDataSet(res.data.paginate_data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    ClassroomList();
  }, [page, perpage]);

  const StudentList = () => {
    return (
      <Container>
        <TableContainer component={Paper} px={30}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>

                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone Number</TableCell>

                <TableCell>Course</TableCell>
                <TableCell>Course Duration (in months)</TableCell>
                {/* <TableCell>Teacher</TableCell>
                            <TableCell>Created at</TableCell>
                            <TableCell>Action</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                students.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {item.id}
                    </TableCell>

                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>{item.phone}</TableCell>

                    <TableCell>{course.name}</TableCell>
                    <TableCell>{course.duration_in_months} Month(s)</TableCell>
                    {/* <TableCell>
                                        {dateFormat(
                                            row.created_at,
                                            "dd mmm, yyyy"
                                        )}
                                    </TableCell> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    );
  };
  return (
    <>
      <Typography variant="h4" gutterBottom>
        {PageLabel}
      </Typography>
      <TableContainer component={Paper} px={30}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>S. No.</TableCell>

              <TableCell>Class Name</TableCell>
              <TableCell>Batch Name</TableCell>

              <TableCell>Course</TableCell>
              <TableCell>Teacher</TableCell>
              <TableCell>Created at</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>

                  <TableCell sx={{ textTransform: "capitalize" }}>
                    {row.title ?? "NA"}
                  </TableCell>
                  <TableCell sx={{ textTransform: "capitalize" }}>
                    {row.batch.name}
                  </TableCell>

                  <TableCell sx={{ textTransform: "capitalize" }}>
                    {row.course.name}
                    <Box fontSize={"11px"}>Fee: {row.course.fee}</Box>
                    <Box fontSize={"11px"}>
                      Duration: {row.course.duration_in_months} Month(s)
                    </Box>
                  </TableCell>
                  <TableCell sx={{ textTransform: "capitalize" }}>
                    {row.teacher.name}
                    <Box
                      fontSize={"11px"}
                      display={"flex"}
                      gap={0.5}
                      alignItems={"center"}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        width={"12px"}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                        />
                      </svg>
                      <Box>{row.teacher.phone}</Box>
                    </Box>
                  </TableCell>

                  <TableCell>
                    {dateFormat(row.created_at, "dd mmm, yyyy HH:MM:ss")}
                  </TableCell>
                  <TableCell>
                    <Box display={"flex"} gap={0.5}>
                      <IconButton
                        size="small"
                        aria-label="edit"
                        color="primary"
                        sx={{
                          border: "1px solid #ddd",
                        }}
                        // onClick={(e) => openSnackbarSet(false)}
                        onClick={(e) => {
                          handleOpenEditClass(e);
                          setEditingClass(true);
                          setClassroomData(row);
                          // onUpdateSuceess()
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>

                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        disableElevation
                        sx={{
                          whiteSpace: "nowrap",
                          textTransform: "capitalize",
                        }}
                        onClick={(e) => {
                          handleClickOpen(e);
                          setStudents(row.students);
                          setCourse(row.course);
                        }}
                      >
                        Students
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <TablePagination
                rowsPerPageOptions={[10, 30, 60, 90, 120, 150]}
                component="div"
                count={paginationData.total}
                rowsPerPage={perpage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
      <CustomTablePaginator
        count={paginationData.total}
        rowsPerPage={perpage}
        page={page}
        onPageChange={(newPage) => {
          setPage(newPage);
        }}
        onChangeRowsPerPage={(newRowsPerPage) => {
          setPerPage(parseInt(newRowsPerPage));
          setPage(0);
        }}
      />
      {students?.length !== 0 ? (
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {/* Call Logs - {?.name} */}
              </Typography>
              <Button autoFocus color="inherit" onClick={handleClose}>
                Close
              </Button>
            </Toolbar>
          </AppBar>
          <Box mt={4}>
            <StudentList />
          </Box>
        </Dialog>
      ) : (
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Call Logs - No Data
              </Typography>
              <Button autoFocus color="inherit" onClick={handleClose}>
                Close
              </Button>
            </Toolbar>
          </AppBar>
          <List>
            <Box sx={{ textAlign: "center", fontSize: 42 }}>No Data</Box>
          </List>
        </Dialog>
      )}

      <Dialog
        fullScreen
        open={editClassroom}
        onClose={handleCloseEditClass}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseEditClass}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            {classroomData.title}
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {/* Call Logs - {?.name} */}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleCloseEditClass}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <Box mt={4}>
          <CreateClassroom
            editingClass={editingClass}
            classroomData={classroomData}
            handleCloseEditClass={handleCloseEditClass}
            onUpdate={ClassroomList}
          />
        </Box>
      </Dialog>
    </>
  );
}
