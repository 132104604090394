import React, { useState, useEffect, useRef } from "react";
import { Chip, Typography, Box } from "@mui/material";
import FollowUpModal from "./FollowUpModal";
import Button from "@mui/material/Button";
import MaterialTable from "@material-table/core";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import CustomTablePaginator from "./CustomTablePaginator";
import InputLabel from "@mui/material/InputLabel";
// import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { Select, MenuItem } from "@mui/material";
import UserResources from "../api/UserResources";
import { Link, useNavigate } from "react-router-dom";
import DialerBreakResume from "./DialerBreakResume";
import { useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CircularProgress from "@mui/material/CircularProgress";

import dateFormat from "dateformat";
function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

const BreakHistory = ({ PageLabel }) => {
    const formRef = useRef();
    const [dateRange, setDateRange] = React.useState(["", ""]);
    const [startDate, endDate] = dateRange;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [openSnackbar, openSnackbarSet] = React.useState(false);
    const [message, setMessage] = React.useState("");

    const [selectedLeads, selectedLeadsSet] = React.useState([]);

    const [data, setData] = React.useState([]);

    const history = async () => {
        try {
            setLoading(true);
            const res = await UserResources.callHistory({
                startDT: dateFormat(startDate, "yyyy-mm-dd hh:MM:ss"),
                endDT: dateFormat(endDate, "yyyy-mm-dd hh:MM:ss"),
            });
            setData(res.data.data.Break);
            console.log("call-history", res.data.data.Break);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
        setLoading(false);
    };

    useEffect(() => {
        history();
    }, [endDate]);

    const LeadPriority = ({ row }) => {
        const priorityMapping = {
            4: "High",
            3: "Medium",
            2: "Low",
            1: "Very Low",
        };
        const value = priorityMapping[row.priority] || "Default Value";
        return (
            <Chip
                label={value}
                color={"primary"}
                variant="outlined"
                size="small"
            />
        );
    };

    // const handleClickAssignModalOpen = () => {
    //     setAssignOpen(true);
    // };
    // const handleAssignModalClose = () => {
    //     setAssignOpen(false);
    // };
    return (
        <>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={(e) => openSnackbarSet(false)}
                message={message}
                TransitionComponent={TransitionUp}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={(e) => openSnackbarSet(false)}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
            <Box>
                <DatePicker
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                        setDateRange(update);
                    }}
                    className="dateRange"
                    isClearable={true}
                />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="h4" gutterBottom>
                    {PageLabel}
                </Typography>
            </Box>

            {!data || loading ? ( // Check if data is empty or loadingEmptyData is true
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {loading ? (
                        <CircularProgress /> // Show loader
                    ) : (
                        <Typography sx={{ textAlign: "center" }}>
                            Please Select Date
                        </Typography>
                    )}
                </Box>
            ) : (
                <MaterialTable
                    title={`Break History`}
                    isLoading={loading}
                    columns={[
                        { title: "Name", field: "name" },
                        { title: "LoginID", field: "loginID" },
                        { title: "Pause Remark", field: "pauseRemark" },
                        { title: "Start Time", field: "startTime" },
                        { title: "End Time", field: "endTime" },
                        { title: "Duration", field: "duration" },
                    ]}
                    data={data}
                    options={{
                        //selection: true,
                        padding: "dense",
                        sorting: true,
                        search: false,
                        exportButton: true,
                        exportFileName: "leads",
                        loadingType: "overlay",
                        emptyRowsWhenPaging: false,
                        paging: false,
                        headerStyle: {
                            backgroundColor: "#f0f0f0",
                        },
                    }}
                    onSelectionChange={(rows, row) => {
                        if (row == undefined && rows.length == 0) {
                            selectedLeadsSet([]);
                            console.log("bbb");
                        }

                        if (row) {
                            let tempIds = [...selectedLeads];

                            if (tempIds.includes(row.id)) {
                                tempIds = tempIds.filter((id) => id != row.id);
                            } else {
                                tempIds = [...tempIds, row.id];
                            }

                            selectedLeadsSet(tempIds);
                        }
                    }}
                />
            )}
            {/* 
            <CustomTablePaginator
                count={paginationData.total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(newPage) => {
                    setPage(newPage);
                }}
                onChangeRowsPerPage={(newRowsPerPage) => {
                    setRowsPerPage(parseInt(newRowsPerPage));
                    setPage(0);
                }}
            /> */}
        </>
    );
};

export default BreakHistory;
