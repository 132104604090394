import React from "react";
import { Box, Grid, Typography, Card, CardContent, Chip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux/es/hooks/useSelector";
import FollowUpModal from "./FollowUpModal";

export default function LeadSingle({ isEnrollmentSingle }) {
	const userInfo = useSelector((state) => state.user.userInfo);
	const navigate = useNavigate();
	const [open, setOpen] = React.useState(false);
	const [status, statusSet] = React.useState("");
	const location = useLocation();
	const [leadID, setLeadId] = React.useState("");
	const { data } = location.state || {};

	const isLeadDetails = location.state.isLeadDetails || false;
	const isAdmin = userInfo.role_type;

	const handleChange = (event) => {
		statusSet(event.target.value);
	};
	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const LeadPriority = ({ row }) => {
		// const priorityMapping = {
		// 	4: "High",
		// 	3: "Medium",
		// 	2: "Low",
		// 	1: "Very Low",
		// };
		// const value = priorityMapping[row.experience_id] || "Default Value";
		// return (
		// 	<Chip
		// 		label={value}
		// 		color={"primary"}
		// 		variant="outlined"
		// 		size="small"
		// 	/>
		// );
		return (
			<>
				<Chip
					label={row.priority_text}
					color={"primary"}
					variant="outlined"
					size="small"
				/>
			</>
		);
	};

	return (
		<>
			<Grid container spacing={3} maxWidth={"lg"}>
				<Grid item sm={6} xs={12}>
					<Card elevation={0}>
						<CardContent>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									borderBottom: "1px solid #ddd",
									py: 2,
								}}
							>
								<Typography
								// color="text.secondary"
								// fontSize={12}
								>
									{"User Details"}
								</Typography>
							</Box>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									borderBottom: "1px solid #ddd",
									py: 2,
								}}
							>
								<Typography
									color="text.secondary"
									fontSize={12}
								>
									{"ID"}
								</Typography>
								<Typography>{data.id}</Typography>
							</Box>



							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									borderBottom: "1px solid #ddd",
									py: 2,
								}}
							>
								<Typography
									color="text.secondary"
									fontSize={12}
								>
									Name
								</Typography>
								<Typography>{data?.name}</Typography>
							</Box>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									borderBottom: "1px solid #ddd",
									py: 2,
								}}
							>
								<Typography
									color="text.secondary"
									fontSize={12}
								>
									Email
								</Typography>
								<Typography>{data?.email}</Typography>
							</Box>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									borderBottom: "1px solid #ddd",
									py: 2,
								}}
							>
								<Typography
									color="text.secondary"
									fontSize={12}
								>
									Contact
								</Typography>
								<Typography>{data?.phone}</Typography>
							</Box>
							{isEnrollmentSingle && (
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
										borderBottom: "1px solid #ddd",
										py: 2,
									}}
								>
									<Typography
										color="text.secondary"
										fontSize={12}
									>
										Course
									</Typography>
									<Typography>
										{data?.course || "NA"}
									</Typography>

									{/* <Typography>{data.course}</Typography> */}
								</Box>
							)}

							<Box mt={2}>
								{!isAdmin ? (
									<Button
										variant="contained"
										color="primary"
										disableElevation
										fullWidth
										onClick={() => {
											setLeadId(data.id);
											handleClickOpen();
										}}
									>
										Create Follow Up
									</Button>
								) : (
									<Button
										variant="contained"
										disableElevation
										size="small"
										onClick={() => {
											navigate(-1);
										}}
									>
										Back
									</Button>
								)}
							</Box>
						</CardContent>
					</Card>
				</Grid>
				{isLeadDetails == true && (
					<Grid item sm={6} xs={12}>
						<Card elevation={0}>
							<CardContent>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										borderBottom: "1px solid #ddd",
										py: 2,
									}}
								>
									<Typography
									// color="text.secondary"
									// fontSize={12}
									>
										{"Lead Details"}
									</Typography>
								</Box>

								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
										borderBottom: "1px solid #ddd",
										py: 2,
									}}
								>
									<Typography
										color="text.secondary"
										fontSize={12}
									>
										Class Type
									</Typography>
									<Box>{data.details.classesType}</Box>
								</Box>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
										borderBottom: "1px solid #ddd",
										py: 2,
									}}
								>
									<Typography
										color="text.secondary"
										fontSize={12}
									>
										Course Interested
									</Typography>
									<Box>
										{data.details.onlineRecordedClasses}
									</Box>
								</Box>

								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
										borderBottom: "1px solid #ddd",
										py: 2,
									}}
								>
									<Typography
										color="text.secondary"
										fontSize={12}
									>
										Profession
									</Typography>
									<Typography>
										{data.details.profession}
									</Typography>
								</Box>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
										borderBottom: "1px solid #ddd",
										py: 2,
									}}
								>
									<Typography
										color="text.secondary"
										fontSize={12}
									>
										Experience
									</Typography>
									<Typography>
										{data.details.experience}
									</Typography>
								</Box>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
										borderBottom: "1px solid #ddd",
										py: 2,
									}}
								>
									<Typography
										color="text.secondary"
										fontSize={12}
									>
										Lead Priority
									</Typography>
									<Typography>
										<LeadPriority row={data} />
									</Typography>
								</Box>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
										borderBottom: "1px solid #ddd",
										py: 2,
									}}
								>
									<Typography
										color="text.secondary"
										fontSize={12}
									>
										Location
									</Typography>
									<Typography>
										{data.details.location}
									</Typography>
								</Box>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
										borderBottom: "1px solid #ddd",
										py: 2,
									}}
								>
									<Typography
										color="text.secondary"
										fontSize={12}
									>
										Earning
									</Typography>
									<Typography>
										{data.details.earning}
									</Typography>
								</Box>
							</CardContent>
						</Card>
					</Grid>
				)}
			</Grid>

			<FollowUpModal
				handleClose={handleClose}
				open={open}
				leadID={leadID}
			/>
		</>
	);
}
