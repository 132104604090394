import React, { useState, useEffect, useRef } from "react";
import { Chip, Typography, Box } from "@mui/material";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FollowUpModal from "./FollowUpModal";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Select, MenuItem } from "@mui/material";
import { TablePagination } from "@mui/material";
import UserResources from "../api/UserResources";
import { Link, useNavigate } from "react-router-dom";
import DialerBreakResume from "./DialerBreakResume";
import { useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dateFormat from "dateformat";

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

export default function AssignedLeads({ PageLabel }) {
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [allUserLoading, setAllUserLoading] = useState(false);
    const [data, setData] = useState([]);
    const [paginationData, paginationDataSet] = useState([]);
    const [pg, setpg] = React.useState(1);
    const [rpg, setrpg] = React.useState(10);
    const [open, setOpen] = React.useState(false);

    const [assignOpen, setAssignOpen] = React.useState(false);
    const [openSnackbar, openSnackbarSet] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [allHandlers, allHandlersSet] = React.useState([]);
    const [handlerID, handlerIDSet] = React.useState("");
    const [selectedID, selectedIDSet] = useState();

    const [leadID, setLeadId] = React.useState("");
    const userInfo = useSelector((state) => state.user.userInfo);

    const [dateRange, setDateRange] = useState(["", ""]);
    const [startDate, endDate] = dateRange;

    function handleChangePage(event, newpage) {
        setpg(newpage);
    }

    function handleChangeRowsPerPage(event) {
        setrpg(parseInt(event.target.value));
        setpg(0);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleAssign = async (params) => {
        var params = {
            user_id: handlerID,
            id: selectedID,
        };

        try {
            const res = await UserResources.assignLead(params);

            if (res.data.status) {
                openSnackbarSet(true);
                setMessage(res.data.message);
                setAssignOpen(false);
                AllLeads();
            }
        } catch (error) {
            openSnackbarSet(true);
            setMessage("Something went wrong! Please try later.");
        }
    };

    const LeadPriority = ({ row }) => {
        const priorityMapping = {
            4: "High",
            3: "Medium",
            2: "Low",
            1: "Very Low",
        };
        const value = priorityMapping[row.priority] || "Default Value";
        return (
            <Chip
                label={value}
                color={"primary"}
                variant="outlined"
                size="small"
            />
        );
    };

    const AllLeads = async (params) => {
        // e.preventDefault();
        setLoading(true);

        var params = {
            limit: rpg,
            page: pg,
            user_id: userInfo.id,
            keyword: search,
            start_date:
                startDate && dateFormat(startDate, "yyyy-mm-dd hh:MM:ss"),
            end_date: endDate && dateFormat(endDate, "yyyy-mm-dd hh:MM:ss"),
        };

        try {
            const res = await UserResources.getLeadList(params);
            if (res.status) {
                setData(res.data.data);
                paginationDataSet(res.data.paginate_data);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };
    useEffect(() => {
        AllLeads();
    }, [rpg, pg, search, startDate, endDate]);

    const UserList = async (params) => {

        setAllUserLoading(true);
        var params = {
            role_type: 2
        }, params;
        try {
            const res = await UserResources.user_list(params);

            if (res.status) {
                // const handlers = res.data.data.filter((u) => u.role_type == 2);
                allHandlersSet(res.data.data);
            }
        } catch (error) {
            console.log(error);
        }
        setAllUserLoading(false);
    };

    React.useEffect(() => {
        UserList();
    }, [search]);

    const handleClickAssignModalOpen = () => {
        setAssignOpen(true);
    };
    const handleAssignModalClose = () => {
        setAssignOpen(false);
    };

    return (
        <>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={(e) => openSnackbarSet(false)}
                message={message}
                TransitionComponent={TransitionUp}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={(e) => openSnackbarSet(false)}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="h4" gutterBottom>
                    {PageLabel}
                </Typography>
                <Box>
                    {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate("/upload-leads")}
                        sx={{ marginRight: 5 }}
                    >
                        Upload Leads
                    </Button> */}

                    <DialerBreakResume />
                </Box>
            </Box>
            <Box sx={{ my: 3, display: "flex", gap: 1 }}>
                {/* <SearchBar setSearchQuery={setSearch} search={search} /> */}
                <form>
                    <TextField
                        // id="search-bar"
                        className="text"
                        onInput={(e) => {
                            setSearch(e.target.value);
                        }}
                        // onChange={(e) => {
                        //     setSearchQuery(e.target.value);
                        // }}
                        label="Enter keyword"
                        variant="outlined"
                        placeholder="Search..."
                        size="small"
                        value={search}
                    />
                    <IconButton onSubmit={AllLeads} aria-label="search">
                        <SearchIcon style={{ fill: "blue" }} />
                    </IconButton>
                </form>
                <DatePicker
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                        setDateRange(update);
                    }}
                    className="dateRange"
                    isClearable={true}
                />
            </Box>
            <TableContainer component={Paper} px={30}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Lead ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Contact No.</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Location</TableCell>
                            <TableCell>Experience</TableCell>
                            <TableCell>Priority</TableCell>
                            <TableCell>Assigned To</TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading &&
                            data.map((row, index) => (
                                <TableRow key={index}>
                                    {console.log("item---", row)}
                                    <TableCell>
                                        <Box sx={{ whiteSpace: "nowrap" }}>
                                            {row.id}
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        {/* <Button onClick={() => navigate('/')}>
                                            {row.name}
                                        </Button> */}
                                        <Link
                                            to={`/view-lead/${row.id}`}
                                            state={{
                                                data: row,
                                                isLeadDetails: true,
                                            }}
                                            title="Manage as admin"
                                        >
                                            {row.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{row.phone}</TableCell>
                                    <TableCell>{row.email}</TableCell>
                                    <TableCell>
                                        {row.details.location}
                                    </TableCell>
                                    <TableCell>
                                        {row.details.experience}
                                    </TableCell>
                                    <TableCell>
                                        <LeadPriority row={row} />
                                    </TableCell>
                                    <TableCell
                                        sx={{ textTransform: "capitalize" }}
                                    >
                                        {row && row.user ? row.user.name : ""}
                                    </TableCell>
                                    <TableCell>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            {/* {userInfo.role_type == 1 && (
                                                <Button
                                                    variant="contained"
                                                    disableElevation
                                                    size="small"
                                                    color="secondary"
                                                    onClick={(e) => {
                                                        handleClickAssignModalOpen(e);
                                                        selectedIDSet(row.id);
                                                    }}
                                                >
                                                    Assign
                                                </Button>
                                            )} */}
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disableElevation
                                                sx={{
                                                    whiteSpace: "nowrap",
                                                    fontSize: 12,
                                                    mx: 1,
                                                }}
                                                size="small"
                                                onClick={() => {
                                                    setLeadId(row.id);
                                                    handleClickOpen();
                                                }}
                                            >
                                                Follow Up
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disableElevation
                                                sx={{
                                                    whiteSpace: "nowrap",
                                                    fontSize: 12,
                                                }}
                                                size="small"
                                                onClick={async () => {
                                                    // const additionalParams = encodeURIComponent(`#${userInfo.id}#${row.id}`);
                                                    // console.log(additionalParams)
                                                    const ans =
                                                        await UserResources.dialerCall(
                                                            {
                                                                src: userInfo.extension_no,
                                                                dst: row.phone,
                                                                ref_id: `TG`,
                                                                // ref_id: `TG${additionalParams}`,
                                                                lead_id: row.id,
                                                                user_id:
                                                                    userInfo.id,
                                                            }
                                                        );
                                                    // console.log("answer", ans);
                                                }}
                                            >
                                                Call
                                            </Button>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[10, 30, 60, 90, 120, 150]}
                component="div"
                count={paginationData.total}
                rowsPerPage={rpg}
                page={pg}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <FollowUpModal
                handleClose={handleClose}
                open={open}
                // handleChange={handleChange}
                leadID={leadID}
            />

            <Dialog
                open={assignOpen}
                onClose={handleClickAssignModalOpen}
                minWidth={"md"}
                fullWidth
            >
                <DialogTitle>Assign Lead ID - {selectedID}</DialogTitle>
                <DialogContent sx={{ minHeight: "320px" }}>
                    <Box
                        component={"form"}
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleAssign(e);
                        }}
                    >
                        <Box my={1}>
                            <FormControl fullWidth>
                                <InputLabel id="select">
                                    Select Status
                                </InputLabel>
                                <Select
                                    labelId="select"
                                    id="demo-simple-select"
                                    value={handlerID}
                                    label={"Select Management"}
                                    onChange={(e) =>
                                        handlerIDSet(e.target.value)
                                    }
                                >
                                    {allHandlers.map((item) => (
                                        <MenuItem
                                            key={item.value}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <DialogActions
                            sx={{
                                pb: 3,
                                px: 3,
                                justifyContent: "space-between",
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleAssignModalClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                disableElevation
                                type="submit"
                            >
                                Update
                            </Button>
                        </DialogActions>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}
