let config = null;

const local = {
  api_url: "http://127.0.0.1:8000/api",
};

const staging = {
  api_url: "https://trading-game-backend.jycyberhub.com/api",
};

const production = {
  api_url: "https://api.tradinggamestrong.com/api",
};

config = production;
// config = staging;

console.log("Environment", process.env.NODE_ENV);

if (process.env.NODE_ENV === "local") {
    config = local;
}
if (process.env.NODE_ENV === "production") {
    config = production;
}

console.log({ config });

export default config;
