import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import UserResources from "../api/UserResources";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
const DialerBreakResume = () => {
    const userInfo = useSelector((state) => state.user.userInfo);
    const [breakValue, setBreakValue] = React.useState("");

    const [alignment, setAlignment] = React.useState("resume");

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const updateBreak = async (event) => {
        try {
            const res = await UserResources.dialerBreak({
                user_email_id: userInfo.email,
                reason: breakValue,
            });
            setBreakValue(null);
        } catch (error) {
            console.log(error);
        }
    };

    const Resume = async () => {
        try {
            const ans = await UserResources.dialerResume({
                user_email_id: userInfo.email,
            });
            console.log("ans Resume", ans);
        } catch (error) {
            console.log(error);
        }
    };
    React.useEffect(() => {
        if (breakValue !== "") {
            updateBreak();
        }
    }, [breakValue]);

    return (
        <ToggleButtonGroup
            color="secondary"
            value={alignment}
            exclusive
            onChange={handleChange}
            size="small"
            aria-label="Platform"
        >
            {/* <ToggleButton
                value="break"
                onClick={() => {
                    Break();
                }}
            >
                Break
            </ToggleButton> */}
            <FormControl sx={{ minWidth: 90 }} fullWidth>
                <InputLabel id="demo-simple-select-autowidth-label">
                    Break
                </InputLabel>
                <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={breakValue}
                    onChange={(e) => setBreakValue(e.target.value)}
                    autoWidth
                    label="Break"
                >
                    <MenuItem value={"15 minutes of bio break"}>
                        15 minutes of bio break
                    </MenuItem>
                    <MenuItem value={"30 minutes of lunch"}>
                        30 minutes of lunch
                    </MenuItem>
                    <MenuItem value={"15 minutes of tea break"}>
                        15 minutes of tea break
                    </MenuItem>
                </Select>
            </FormControl>
            <ToggleButton
                size="small"
                value="resume"
                onClick={() => {
                    Resume();
                }}
            >
                Resume
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

export default DialerBreakResume;
