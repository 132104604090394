import React, { useState, useEffect, useRef } from "react";
import { Chip, Typography, Box } from "@mui/material";
import FollowUpModal from "./FollowUpModal";
import Button from "@mui/material/Button";
import MaterialTable from "@material-table/core";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import CustomTablePaginator from "./CustomTablePaginator";
import InputLabel from "@mui/material/InputLabel";
// import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { Select, MenuItem } from "@mui/material";
import UserResources from "../api/UserResources";
import { Link, useNavigate } from "react-router-dom";
import DialerBreakResume from "./DialerBreakResume";
import { useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dateFormat from "dateformat";
function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

export default function LeadList({ PageLabel }) {
    const formRef = useRef();
    const navigate = useNavigate();

    const [dateRange, setDateRange] = React.useState(["", ""]);
    const [startDate, endDate] = dateRange;

    const [loading, setLoading] = useState(false);
    const [allUserLoading, setAllUserLoading] = useState(false);
    const [data, setData] = useState([]);
    // const [paginationData, paginationDataSet] = useState([]);
    // const [pg, setpg] = React.useState(1);
    // const [rpg, setrpg] = React.useState(10);
    const [open, setOpen] = React.useState(false);

    const [assignOpen, setAssignOpen] = React.useState(false);
    const [openSnackbar, openSnackbarSet] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [allHandlers, allHandlersSet] = React.useState([]);
    const [handlerID, handlerIDSet] = React.useState("");
    const [selectedID, selectedIDSet] = useState();
    const [selectedLeads, selectedLeadsSet] = React.useState([]);
    const [search, setSearch] = useState("");

    const [leadID, setLeadId] = React.useState("");
    const userInfo = useSelector((state) => state.user.userInfo);

    const defaultPerPage = 25;
    const [paginationData, setPaginationData] = useState({
        count: 0,
        total: 0,
        perPage: defaultPerPage,
        haMorePage: false,
        nextPageUrl: null,
        previousPageUrl: null,
        currentPage: 1,
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultPerPage);

    // console.log("selectedLeads", selectedLeads);

    // function handleChangePage(event, newpage) {
    //     setpg(newpage);
    // }

    // function handleChangeRowsPerPage(event) {
    //     setrpg(parseInt(event.target.value));
    //     setpg(0);
    // }

    // const handleChange = (event) => {
    //     setAge(event.target.value);
    // };
    const handleClose = () => {
        setOpen(false);
    };
    // const handleChange = (event) => {
    //     setAge(event.target.value);
    // };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleAssign = async (params) => {
        var params = {
            user_id: handlerID,
            // id: selectedID
            bulk_assign: selectedLeads,
        };

        try {
            const res = await UserResources.assignLead(params);

            // console.log(params)

            if (res.data.status) {
                openSnackbarSet(true);
                setMessage(res.data.message);
                setAssignOpen(false);
                AllLeads();
            }
        } catch (error) {
            openSnackbarSet(true);
            setMessage("Something went wrong! Please try later.");
        }
    };

    const LeadPriority = ({ row }) => {
        const priorityMapping = {
            4: "High",
            3: "Medium",
            2: "Low",
            1: "Very Low",
        };
        const value = priorityMapping[row.priority] || "Default Value";
        return (
            <Chip
                label={value}
                color={"primary"}
                variant="outlined"
                size="small"
            />
        );
    };

    const AllLeads = async (params) => {
        // e.preventDefault();
        setLoading(true);

        var params = Object.assign(
            {
                page: page + 1,
                limit: rowsPerPage,
                keyword: search,
                start_date:
                    startDate && dateFormat(startDate, "yyyy-mm-dd hh:MM:ss"),
                end_date: endDate && dateFormat(endDate, "yyyy-mm-dd hh:MM:ss"),
            },
            params
        );

        try {
            const res = await UserResources.getLeadList(params);
            if (res.status) {
                setData(res.data.data);
                setPaginationData(res.data.paginate_data);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        AllLeads();
        //console.log("startDate", startDate.toISOString());
        // console.log("rpg", rpg);
    }, [page, rowsPerPage, search, startDate, endDate]);

    const UserList = async (params) => {
        setAllUserLoading(true);
        var params = {
            role_type: 2
        }, params;
        try {
            const res = await UserResources.user_list(params);

            if (res.status) {
                // const handlers = res.data.data.filter((u) => u.role_type == 2);
                allHandlersSet(res.data.data);
            }
        } catch (error) {
            console.log(error);
        }
        setAllUserLoading(false);
    };

    React.useEffect(() => {
        UserList();
    }, [search]);

    const handleClickAssignModalOpen = () => {
        setAssignOpen(true);
    };
    const handleAssignModalClose = () => {
        setAssignOpen(false);
    };

    return (
        <>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={(e) => openSnackbarSet(false)}
                message={message}
                TransitionComponent={TransitionUp}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={(e) => openSnackbarSet(false)}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="h4" gutterBottom>
                    {PageLabel}
                </Typography>

                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() => navigate("/upload-leads")}
                        sx={{ marginRight: 5 }}
                    >
                        Upload Leads
                    </Button>

                    <Button
                        key="assing-button"
                        variant="contained"
                        color="secondary"
                        sx={{ mr: 1 }}
                        onClick={(e) => {
                            handleClickAssignModalOpen(e);
                            selectedIDSet(selectedLeads);
                        }}
                        disableElevation
                        disabled={selectedLeads.length == 0}
                    >
                        Assign
                    </Button>
                    <DialerBreakResume />
                </Box>
            </Box>
            <Box sx={{ my: 3, width: "100%", display: "flex" }}>
                <form onSubmit={AllLeads}>
                    <TextField
                        id="search-bar"
                        className="text"
                        onInput={(e) => {
                            setSearch(e.target.value);
                        }}
                        label="Enter keyword"
                        variant="outlined"
                        placeholder="Search..."
                        size="small"
                        value={search}
                    />
                    <IconButton type="submit" aria-label="search">
                        <SearchIcon style={{ fill: "blue" }} />
                    </IconButton>
                    <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                            setDateRange(update);
                        }}
                        className="dateRange"
                        isClearable={true}
                    />
                </form>
            </Box>

            <MaterialTable
                title={`Manage Leads`}
                isLoading={loading}
                columns={[
                    {
                        title: "Name",
                        field: "name",
                        render: (rowData) => (
                            <>
                                <Link
                                    to={`/view-lead/${rowData.id}`}
                                    state={{
                                        data: rowData,
                                        isLeadDetails: true,
                                    }}
                                    title="Manage as admin"
                                    style={{
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        textTransform: "capitalize",
                                    }}
                                >
                                    {rowData.name}
                                </Link>
                                <Box sx={{ fontSize: 12 }}>{rowData.phone}</Box>
                            </>
                        ),
                    },
                    { title: "Email", field: "email" },
                    {
                        title: "Location",
                        field: "details.location",
                    },
                    {
                        title: "Experience",
                        field: "details.experience",
                    },
                    {
                        title: "Priority",
                        field: "",
                        render: (rowData) => <LeadPriority row={rowData} />,
                    },
                    {
                        title: "Assigned To",
                        field: "user.name",
                    },
                    {
                        title: "Actions",
                        field: "user.name",
                        render: (rowData) => (
                            <Box sx={{ display: "flex" }}>
                                {/* <Button
                                    variant="contained"
                                    disableElevation
                                    size="small"
                                    color="secondary"
                                    onClick={(e) => {
                                        handleClickAssignModalOpen(e)
                                        selectedIDSet(rowData.id);
                                    }}
                                >
                                    Assign
                                </Button> */}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    sx={{
                                        whiteSpace: "nowrap",
                                        fontSize: 12,
                                        mx: 1,
                                    }}
                                    size="small"
                                    onClick={() => {
                                        setLeadId(rowData.id);
                                        handleClickOpen();
                                    }}
                                >
                                    Follow Up
                                </Button>
                                {/* {console.log("id----->", rowData.id)} */}
                                {/* <Button
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    sx={{
                                        whiteSpace: "nowrap",
                                        fontSize: 12,
                                        mx: 1,
                                    }}
                                    size="small"
                                    onClick={() =>
                                        navigate(
                                            `/create-enrollment/${rowData.id}`,
                                            {
                                                state: rowData,
                                            }
                                        )
                                    }
                                >
                                    create Enrollment
                                </Button> */}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    sx={{
                                        whiteSpace: "nowrap",
                                        fontSize: 12,
                                        mx: 1,
                                    }}
                                    size="small"
                                    onClick={() =>
                                        navigate(`/add-user`, {
                                            state: rowData,
                                        })
                                    }
                                >
                                    Create Student
                                </Button>
                            </Box>
                        ),
                    },
                ]}
                data={data}
                options={{
                    selection: true,
                    padding: "dense",
                    sorting: true,
                    search: false,
                    exportButton: true,
                    exportFileName: "leads",
                    loadingType: "overlay",
                    emptyRowsWhenPaging: false,
                    // filtering: true,
                    // actionsColumnIndex: -1,
                    // selectionProps: rowData => ({
                    //     checked: selectedLeads.includes(rowData.id),
                    // }),
                    paging: false,
                    headerStyle: {
                        backgroundColor: "#f0f0f0",
                        // color: "#ffffff"
                    },
                }}
                actions={[
                    // {
                    //     tooltip: 'Assign Leads',
                    //     icon: 'delete',
                    //     onClick: (evt, data) => alert('You want to delete ' + data.length + ' rows')
                    // },
                    {
                        tooltip: "Refresh",
                        icon: "refresh",
                        isFreeAction: true,
                        onClick: (e) => {
                            // console.log("aaaaa");

                            AllLeads(e);
                        },
                    },
                ]}
                // onSelectionChange={(rows) => console.log(rows)}
                onSelectionChange={(rows, row) => {
                    // if(row == undefined && rows.length === users.length) {
                    //     selectedLeadsSet(users.map(user => user.id))
                    // }

                    // if(row == undefined && rows.length < users.length) {
                    //     let tempIds = [...selectedLeads, ...(rows.map(row => row.id))].filter((v, i, a) => a.indexOf(v) === i);
                    //     selectedLeadsSet(tempIds)
                    // }

                    if (row == undefined && rows.length == 0) {
                        selectedLeadsSet([]);
                        // console.log("bbb");
                    }

                    if (row) {
                        let tempIds = [...selectedLeads];

                        if (tempIds.includes(row.id)) {
                            tempIds = tempIds.filter((id) => id != row.id);
                        } else {
                            tempIds = [...tempIds, row.id];
                        }

                        selectedLeadsSet(tempIds);
                    }
                }}
            />

            <CustomTablePaginator
                count={paginationData.total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(newPage) => {
                    setPage(newPage);
                }}
                onChangeRowsPerPage={(newRowsPerPage) => {
                    setRowsPerPage(parseInt(newRowsPerPage));
                    setPage(0);
                }}
            />

            <FollowUpModal
                handleClose={handleClose}
                open={open}
                // handleChange={handleChange}
                leadID={leadID}
            />

            <Dialog
                open={assignOpen}
                onClose={handleClickAssignModalOpen}
                maxWidth={"sm"}
                fullWidth
            >
                <DialogTitle>Assign Lead ID - {selectedID}</DialogTitle>
                <DialogContent sx={{ minHeight: "320px" }}>
                    <Box
                        component={"form"}
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleAssign(e);
                        }}
                    >
                        <Box my={1}>
                            <FormControl fullWidth>
                                <InputLabel id="select">
                                    Select Status
                                </InputLabel>
                                <Select
                                    labelId="select"
                                    id="demo-simple-select"
                                    value={handlerID}
                                    label={"Select Management"}
                                    onChange={(e) =>
                                        handlerIDSet(e.target.value)
                                    }
                                >
                                    {allHandlers.map((item) => (
                                        <MenuItem
                                            key={item.value}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <DialogActions
                            sx={{
                                pb: 3,
                                px: 3,
                                justifyContent: "space-between",
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleAssignModalClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                disableElevation
                                type="submit"
                            >
                                Update
                            </Button>
                        </DialogActions>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}
