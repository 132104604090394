import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextsmsIcon from "@mui/icons-material/Textsms";
import RingVolumeIcon from "@mui/icons-material/RingVolume";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import PhoneIcon from "@mui/icons-material/Phone";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useSelector } from "react-redux";
import {
    Box,
    Typography,
    Chip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Tooltip,
    Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { TablePagination } from "@mui/material";
import FollowUpModal from "./FollowUpModal";
import UserResources from "../api/UserResources";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
// import Fab from '@mui/material/Fab';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import CustomTablePaginator from "./CustomTablePaginator";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FollowUps() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [paginationData, paginationDataSet] = useState([]);
    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(10);
    const [open, setOpen] = React.useState(false);
    const [courseOpen, setCourceOpen] = useState(false);
    const [followUpId, setFollowUpId] = React.useState();
    const [leadId, setLeadId] = React.useState();
    const [filterSection, setFilterSection] = React.useState("");
    const [Alldata, setAlldata] = useState([]);
    const userInfo = useSelector((state) => state.user.userInfo);

    const sectionFilter = async (event) => {
        setFilterSection(event.target.value);
        setLoading(true);

        const params = {
            limit: 9999,
            page: pg,
            user_id: userInfo?.id === 2 ? "" : userInfo?.id,
        };

        try {
            const response = await UserResources.followupList(params);
            if (response.status) {
                const filterResult = response.data.data.filter(
                    (val) => val.status === event.target.value
                );
                setData(filterResult);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    function handleChangePage(event, newpage) {
        setpg(newpage);
    }

    function handleChangeRowsPerPage(event) {
        setrpg(parseInt(event.target.value));
        setpg(1);
    }

    const FollowUps = async () => {
        setLoading(true);

        const params = {
            limit: rpg,
            page: pg,
            user_id: userInfo?.id === 2 ? "" : userInfo?.id,
        };

        try {
            const res = await UserResources.followupList(params);
            if (res.status) {
                setData(res.data.data);
                paginationDataSet(res.data.paginate_data);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        FollowUps();
    }, [rpg, pg]);
    const newData = [...data];
    const reverseData = newData.reverse();

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const clickOpen = () => {
        setCourceOpen(true);
    };
    const clickClose = () => {
        setCourceOpen(false);
    };

    const groupedByLeadId = reverseData.reduce((result, currentObject) => {
        const leadId = currentObject.lead_id;

        if (!result[leadId]) {
            result[leadId] = [];
        }

        result[leadId].push(currentObject);

        return result;
    }, {});

    const AllfollowUps = () => {
        return (
            <Box>
                {Alldata?.length !== 1 ? (
                    <Dialog
                        fullScreen
                        open={courseOpen}
                        onClose={clickClose}
                        TransitionComponent={Transition}
                    >
                        <AppBar sx={{ position: "relative" }}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={clickClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Typography
                                    sx={{ ml: 2, flex: 1 }}
                                    variant="h6"
                                    component="div"
                                >
                                    History
                                </Typography>
                                <Button
                                    autoFocus
                                    color="inherit"
                                    onClick={clickClose}
                                >
                                    Close
                                </Button>
                            </Toolbar>
                        </AppBar>
                        <TableContainer
                            component={Paper}
                            sx={{ margin: "20px 0" }}
                        >
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Lead ID</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Contacts</TableCell>
                                        <TableCell>Current Status</TableCell>
                                        <TableCell>Follow Ups</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Alldata.map((i, k) => (
                                        <TableRow key={k}>
                                            <TableCell>{i.lead_id}</TableCell>
                                            <TableCell>{i.lead.name}</TableCell>
                                            <TableCell>
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <ForwardToInboxIcon
                                                        sx={{
                                                            fontSize: 12,
                                                            marginRight: 1,
                                                        }}
                                                    />{" "}
                                                    {i.lead.email}
                                                    {/* {console.log(rowGroup)} */}
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <PhoneIcon
                                                        sx={{
                                                            fontSize: 12,
                                                            marginRight: 1,
                                                        }}
                                                    />
                                                    {i.lead.phone}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Chip
                                                    label={
                                                        i.status === "1"
                                                            ? "Call Later"
                                                            : i.status === "2"
                                                            ? "Call Not Received"
                                                            : i.status === "3"
                                                            ? "Potential Lead"
                                                            : i.status === "4"
                                                            ? "Not Interested"
                                                            : i.status === "5"
                                                            ? "Converted"
                                                            : i.status === "6"
                                                            ? "Interested later"
                                                            : "NA"
                                                    }
                                                    sx={{
                                                        height: "20px",
                                                        fontSize: "13px",
                                                    }}
                                                    color="primary"
                                                    variant="outlined"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <TextsmsIcon
                                                        sx={{
                                                            fontSize: 12,
                                                            marginRight: 1,
                                                        }}
                                                    />
                                                    {i.description}
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    <RingVolumeIcon
                                                        sx={{
                                                            fontSize: 12,
                                                            marginRight: 1,
                                                        }}
                                                    />
                                                    {i.next_date}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Dialog>
                ) : (
                    <Dialog
                        fullScreen
                        open={courseOpen}
                        onClose={handleClose}
                        TransitionComponent={Transition}
                    >
                        <AppBar sx={{ position: "relative" }}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={clickClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Typography
                                    sx={{ ml: 2, flex: 1 }}
                                    variant="h6"
                                    component="div"
                                >
                                    History Not available
                                </Typography>
                                <Button
                                    autoFocus
                                    color="inherit"
                                    onClick={clickClose}
                                >
                                    Close
                                </Button>
                            </Toolbar>
                        </AppBar>
                        <List>
                            <Box sx={{ textAlign: "center", fontSize: 42 }}>
                                History Not available
                            </Box>
                        </List>
                    </Dialog>
                )}
            </Box>
        );
    };

    const groupedArray = Object.values(groupedByLeadId);


    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="h4" gutterBottom>
                    Follow Ups
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Tooltip title="Clear Filter" cursor="pointer">
                        <HighlightOffIcon
                            onClick={() => {
                                FollowUps();
                                setFilterSection(null);
                            }}
                        />
                    </Tooltip>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-select-small-label">
                            Filter
                        </InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={filterSection}
                            label="Filter"
                            onChange={sectionFilter}
                        >
                            <MenuItem value={"1"}>Call later</MenuItem>
                            <MenuItem value={"2"}>Call not received</MenuItem>
                            <MenuItem value={"3"}>Potential lead</MenuItem>
                            <MenuItem value={"4"}>Not Interested</MenuItem>
                            <MenuItem value={"5"}>Converted</MenuItem>
                            <MenuItem value={"6"}>Interested later</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>

            <TableContainer component={Paper} sx={{ margin: "20px 0" }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Lead ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Contacts</TableCell>
                            <TableCell>Current Status</TableCell>
                            <TableCell>Follow Ups</TableCell>
                            <TableCell>Update</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading &&
                            groupedArray.reverse().map((rowGroup, index) => (
                                <TableRow key={index}>
                                    {/* Only print the first index of rowGroup */}
                                    {rowGroup[0] && (
                                        <>
                                            <TableCell>
                                                {rowGroup[0].lead_id}
                                            </TableCell>
                                            <TableCell>
                                                {rowGroup[0].lead.name}
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <ForwardToInboxIcon
                                                        sx={{
                                                            fontSize: 12,
                                                            marginRight: 1,
                                                        }}
                                                    />{" "}
                                                    {rowGroup[0].lead.email}
                                                    {/* {console.log(rowGroup)} */}
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <PhoneIcon
                                                        sx={{
                                                            fontSize: 12,
                                                            marginRight: 1,
                                                        }}
                                                    />
                                                    {rowGroup[0].lead.phone}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Chip
                                                    label={
                                                        rowGroup[0].status ===
                                                        "1"
                                                            ? "Call Later"
                                                            : rowGroup[0]
                                                                  .status ===
                                                              "2"
                                                            ? "Call Not Received"
                                                            : rowGroup[0]
                                                                  .status ===
                                                              "3"
                                                            ? "Potential Lead"
                                                            : rowGroup[0]
                                                                  .status ===
                                                              "4"
                                                            ? "Not Interested"
                                                            : rowGroup[0]
                                                                  .status ===
                                                              "5"
                                                            ? "Converted"
                                                            : rowGroup[0]
                                                                  .status ===
                                                              "6"
                                                            ? "Interested later"
                                                            : "NA"
                                                    }
                                                    sx={{
                                                        height: "20px",
                                                        fontSize: "13px",
                                                    }}
                                                    color="primary"
                                                    variant="outlined"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <TextsmsIcon
                                                        sx={{
                                                            fontSize: 12,
                                                            marginRight: 1,
                                                        }}
                                                    />
                                                    {rowGroup[0].description}
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    <RingVolumeIcon
                                                        sx={{
                                                            fontSize: 12,
                                                            marginRight: 1,
                                                        }}
                                                    />
                                                    {rowGroup[0].next_date}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <EditIcon
                                                    sx={{ cursor: "pointer" }}
                                                    color="primary"
                                                    onClick={() => {
                                                        setFollowUpId(
                                                            rowGroup[0].id
                                                        );
                                                        setLeadId(
                                                            rowGroup[0].lead_id
                                                        );
                                                        handleClickOpen();
                                                    }}
                                                ></EditIcon>
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    disableElevation
                                                    onClick={(e) => {
                                                        clickOpen();
                                                        setAlldata(rowGroup);
                                                    }}
                                                >
                                                    History
                                                </Button>
                                            </TableCell>
                                        </>
                                    )}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <AllfollowUps />
            {/* <TablePagination
                rowsPerPageOptions={[10, 30, 60, 90, 120, 150]}
                component="div"
                count={paginationData.total}
                rowsPerPage={rpg}
                page={pg}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
            <CustomTablePaginator
                count={paginationData.total}
                rowsPerPage={rpg}
                page={pg}
                onPageChange={(newPage) => {
                    setpg(newPage);
                }}
                onChangeRowsPerPage={(newRowsPerPage) => {
                    setrpg(parseInt(newRowsPerPage));
                    setpg(0);
                }}
            />
            <FollowUpModal
                handleClose={handleClose}
                open={open}
                followUpId={followUpId}
                leadID={leadId}
                callfun={FollowUps}
            />
        </Box>
    );
}
